import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import styled from "styled-components";

// Base used for global Wrap of the whole page
export const ColorWrapper = tw.div`text-black bg-palette1-400 font-display`;
export const Wrapper = styled.div(() => [
    tw`overflow-hidden min-h-screen mx-auto`, // Add base styles first
    !window.location.pathname.includes("/display") && tw`max-w-screen-lg`, // Then add conditional styles
])
// Base used for page elements wrap
export const Subwrapper = tw.div`px-4 max-w-screen-sm mx-auto`;