import React, {useState} from "react";
import tw from "twin.macro";

import { css } from "styled-components/macro";
import {useNavigate} from "react-router";
import axiosInstance from "../axios";
import decode from "jwt-decode";
import {Subwrapper} from "../components/templates/Layouts";
import {UserIcon} from "@heroicons/react/solid";
import {loginForm as data} from "../data";


const BodyContainer = tw.div`my-8 bg-palette1-300 p-4 rounded-xl text-center border-solid border-0 border-palette1-500 border-r-4 border-l-4`;
const LogoContainer = tw.div`h-20 w-20 mt-10 text-palette1-100 mx-auto flex p-2 justify-center items-center rounded-full bg-palette1-400 shadow-sm`;

const EmailInput = tw.input`mt-10 mb-1 w-9/12 bg-palette1-400 px-4 font-thin text-lg text-black py-3 border-solid border-0 border-b-2 border-l-2 border-palette1-100 rounded-xl leading-tight hocus:border-palette1-500 hocus:outline-none transition duration-300 hocus:shadow-lg`;
const PasswordInput = tw.input`mt-8 mb-1 w-9/12 bg-palette1-400 px-4 font-thin text-lg text-black py-3 border-solid border-0 border-b-2 border-l-2 border-palette1-100 rounded-xl leading-tight hocus:border-palette1-500 hocus:outline-none transition duration-300 hocus:shadow-lg`;

const Connection = tw.button`w-9/12 px-3 mt-10 mb-3 py-4 rounded-full border-0 text-palette1-100 uppercase font-hairline text-lg bg-palette1-400 shadow-lg transition duration-500 hocus:bg-palette1-100 hocus:text-palette1-400`;
const Register = tw.button`w-9/12 px-3 py-4 mt-5 mb-10 rounded-full border-0 text-palette1-400 uppercase font-hairline text-lg bg-palette1-200 shadow-lg`;

const ErrorLabel = tw.p`my-1 text-red-500 text-base font-medium`;

export default function Login() {

    if (process.env.REACT_APP_IS_DEMO === "TRUE") {
        axiosInstance
            .post(`token/`, {
                email: process.env.REACT_APP_EMAIL,
                password: process.env.REACT_APP_PASSWORD,
            })
            .then((res) => {

                localStorage.setItem('access_token', res.data.access);
                localStorage.setItem('refresh_token', res.data.refresh);
                const {user_id} = decode(localStorage.getItem('refresh_token'));
                localStorage.setItem('user_id', user_id);
                localStorage.setItem('is_staff', res.data.staff);
                localStorage.setItem('scanner', res.data.scanner);


                axiosInstance.defaults.headers['Authorization'] =
                    'JWT ' + localStorage.getItem('access_token');
                setSuccess(true);
                //navigate('/');

                if (localStorage.getItem('is_staff') === "true") {
                    window.location.replace('/admin');
                }
                else if (localStorage.getItem('scanner') === "true"){
                    window.location.replace('/scan');
                }
                else {
                    window.location.replace('/');
                }
            })
            .catch((error) => {
                setError(true)
            });
    }

    const navigate = useNavigate();
    const initialFormData = Object.freeze({
        email: '',
        password: '',
    });

    const [formData, updateFormData] = useState(initialFormData);
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [mdperror, setMdperror] = useState(false)
    const [emailerror, setEmailerror] = useState(false)

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim(),
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //console.log(formData)

        if ( /^\d+$/.test(formData.password) === false) {
            setMdperror(true);
        }
        // On ne fait pas cette vérification car l'admin a un mdp plus long
        //else if (formData.password.length !== 4) {
        //    setMdperror(true);
        //}
        else if (validateEmail(formData.email) === null) {
            setEmailerror(true);
        }
        else {
            axiosInstance
                .post(`token/`, {
                    email: formData.email,
                    password: formData.password,
                })
                .then((res) => {

                    localStorage.setItem('access_token', res.data.access);
                    localStorage.setItem('refresh_token', res.data.refresh);
                    const {user_id} = decode(localStorage.getItem('refresh_token'));
                    localStorage.setItem('user_id', user_id);
                    localStorage.setItem('is_staff', res.data.staff);
                    localStorage.setItem('scanner', res.data.scanner);


                    axiosInstance.defaults.headers['Authorization'] =
                        'JWT ' + localStorage.getItem('access_token');
                    setSuccess(true);
                    //navigate('/');

                    if (localStorage.getItem('is_staff') === "true") {
                        window.location.replace('/admin');
                    }
                    else if (localStorage.getItem('scanner') === "true"){
                        window.location.replace('/scan');
                    }
                    else {
                        window.location.replace('/');
                    }
                })
                .catch((error) => {
                    setError(true)
                });
        }

    };

    const resetMailError = () => {
        setError(false);
        setEmailerror(false);
    };

    const resetMdpError = () => {
        setError(false);
        setMdperror(false);
    }

    return(
        <Subwrapper>
            <BodyContainer>
                <LogoContainer>
                    <UserIcon css={tw`pb-4`}/>
                </LogoContainer>
                <form>
                    <EmailInput
                        css={emailerror ? tw`transition duration-1000 border-2 border-red-500 shadow-lg` : tw`` }
                        type="text"
                        id="email"
                        placeholder={data.email}
                        name="email"
                        autoFocus
                        required
                        onChange={handleChange}
                        onClick={resetMailError}
                        autoComplete="current-email"
                    />
                    <ErrorLabel css={emailerror ? tw`block` : tw`hidden`}>
                        {data.emailError}
                    </ErrorLabel>
                    <PasswordInput
                        css={mdperror ? tw`border-2 border-red-500 shadow-lg` : tw`` }
                        required
                        name="password"
                        placeholder={data.password}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        inputProps={{ inputMode: 'numeric' }}
                        onChange={handleChange}
                        onClick={resetMdpError}
                    />
                    <ErrorLabel css={mdperror ? tw`block` : tw`hidden`}>
                        {data.passwordError}
                    </ErrorLabel>
                    <Connection type="submit" onClick={handleSubmit}>{data.submit}</Connection>
                    <ErrorLabel css={error ? tw`block` : tw`hidden`}>
                        {data.duoError}
                    </ErrorLabel>
                    <Register type={"submit"} onClick={()=> navigate('/register')}>{data.register}</Register>
                </form>
            </BodyContainer>
        </Subwrapper>
    )
}