import Main from "./pages/main"
import {useEffect, useState} from "react";
import axiosInstance from "./axios";


function App(props) {

    const [beers, setBeers] = useState(null);

    useEffect( ()=>{
            axiosInstance.get('/beers/short/').then((res)=>{
                const temp = res.data;
                setBeers(temp);
                //console.log("Beers",temp)
            }).catch((error)=>{
                // Handle error here
            })
        },
        [setBeers]);
        
    return (
        <div>
            {beers !== null &&
                <Main beers={beers} websocket={props.websocket}/>
            }
        </div>
);
}

export default App;
