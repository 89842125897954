import React, {useEffect, useRef, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {XIcon} from "@heroicons/react/solid";


const Wrapper = tw.div`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none`;
const Container = tw.div`relative my-6 flex-grow mx-auto max-w-screen-sm`;
const Content = tw.div`mx-2 border-solid border-0 border-t-2 border-b-2 border-palette1-500 p-3 px-6 rounded-xl shadow-2xl relative flex flex-col bg-palette1-400 outline-none`;

const ErrorLabel = tw.p`text-center mt-0 mb-4 text-red-700 text-base font-medium`;
const Footer =tw.div`flex flex-row justify-center mb-2`;
const TextButton = tw.div`uppercase p-2 rounded-xl border-solid border-palette1-100 text-black font-semibold text-base cursor-pointer transition duration-500 hocus:shadow-lg`;

//Close the component if clicked outside
export function useOutsideClose(ref,handleClose) {
    useEffect(() => {
        /**
         * Close if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                handleClose()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}


export default function MessageErrorPopUp({
                                         handleClose,
                                         okTxt,
                                         error,
                                         errorTxt
                                     }) {


    const wrapperRef = useRef(null);
    useOutsideClose(wrapperRef, handleClose);

    return(
        <Wrapper>
            <Container>
                <Content ref={wrapperRef}>
                    <ErrorLabel css={error ? tw`block` : tw`hidden`}>
                        {errorTxt}
                    </ErrorLabel>
                    <Footer>
                        <TextButton css={tw`mr-3`} onClick={handleClose}>
                            {okTxt}
                        </TextButton>

                    </Footer>
                </Content>
            </Container>
        </Wrapper>
    )
}