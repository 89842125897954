import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {Subwrapper} from "./templates/Layouts";
import {footer as data} from "../data";
import {checkStaff, checkScanner} from "../checkUser";


const TextContainer = tw.div`mt-10 pb-4 text-center font-normal text-xs text-black`;
const Redirection = tw.a`mt-1 ml-2 text-palette1-100`;
const EmptyDiv = tw.div`h-24`;


export default function Footer() {
    return(
        <Subwrapper css={window.location.pathname.includes("/display") ? tw`hidden` : tw``}>
            {!checkStaff() && !checkScanner() &&
                <TextContainer>
                    {data.copyright}
                    <Redirection href={"https://www.thedrinkmarket.fr"}>
                        {data.redirection}
                    </Redirection>
                </TextContainer>
            }
            {checkStaff() &&
                <EmptyDiv></EmptyDiv>
            }
        </Subwrapper>
    )
}