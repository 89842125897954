import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {TrashIcon} from "@heroicons/react/outline";
import {useNavigate} from "react-router";
import PopUpDeleteOrder from "./popUpDeleteOrder";

const Container = tw.div`h-8 w-8 p-3 text-palette1-400 fixed bg-palette1-100 rounded-full shadow-2xl`;

export default function DeleteOrderButton(props) {

    const [open, setOpen] = useState(false)
    const navigate = useNavigate();

    const handleClick = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    return(
        <>
            <Container onClick={handleClick} style={{bottom:15, right:15}}>
                <TrashIcon id={props.id}/>
            </Container>
            {open &&
                <PopUpDeleteOrder id={props.id} pop={open} handlePopClose={handleClose}/>
            }
        </>

    )
}