import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import Grid from "../components/admin/grid";
import Parameters from "../components/admin/parameters";
import axiosInstance from "../axios";
import {Title} from "../components/templates/Titles";
import {dashboard as data} from "../data";
import Status from "../components/admin/status";
import Staffers from "../components/admin/staffers";
import SimulationButton from "../components/admin/simulationButton";
import {checkStaff} from "../checkUser";
import Unauthorized from "../components/unauthorized";

const Container = tw.div`px-4 sm:px-6 md:px-8 xl:px-0 mt-4`;

export default function AdminDesktop() {

    const [beers, setBeers] = useState(null);
    const [parameters, setParameters] = useState(null)

    useEffect( ()=>{
            axiosInstance.get('/').then((res)=>{
                const temp = res.data;
                setBeers(temp);
            }).catch((error)=>{
                // Handle error here
            })
        },
        [setBeers]);

    useEffect( ()=>{
            axiosInstance.get('/parameters/get_parameters').then((res)=>{
                const temp = res.data;
                setParameters(temp);
            }).catch((error)=>{
                // Handle error here
            })
        },
        [setBeers]);

    if (!checkStaff()) {
        return <Unauthorized/>
    }
    else {

        return (
            <Container>
                <Title css={tw`text-center`}>
                    {data.gridTitle}
                </Title>
                <Grid beers={beers} parameters={parameters}/>
                <Title css={tw`text-center`}>
                    {data.parametersTitle}
                </Title>
                {parameters !== null &&
                    <Parameters parameters={parameters}/>
                }
                <Title css={tw`text-center`}>
                    {data.scannersTitle}
                </Title>
                <Staffers/>
                <Title css={tw`text-center`}>
                    {data.statusTitle}
                </Title>
                {parameters !== null &&
                    <Status parameters={parameters}/>
                }
                <SimulationButton/>
            </Container>
        )
    }

}