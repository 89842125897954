export const header = {
    login:"LOGIN",
    logout:"LOGOUT",
    landing:"LANDING"
}

export const main = {
    titles:{
        badges:"Challenges",
        drinks: "Boissons",
    }
}

export const badges = {
    emptyMessage:"Vous n'avez pas encore gagné de badges pour ce Challenge!",
}

export const badgesDetail = {
    validationInfo: "Faites scanner ce QR Code pour récupérer un lot!"
}

export const slider = {
    currency: "€",
    soldout: "Stock épuisé",
    krash: "Krash en cours"
}

export const pinList = {
    title:"Épinglées",
    currency:'€',
    soldout:'Stock épuisé'
}

export const myOrders = {
    title:"Mes Commandes",
}

export const drinkDetail = {
    currency:'€',
    orderButton: "Commander",
    soldout: "Stock épuisé",
    popUp: {
        title:"Êtes-vous sûr ?",
        body:"",
        errorMaxOrders:"Vous avez atteint votre nombre maximum de commandes simultanées...",
        errorEmptyStock:"Désolé, les stocks sont vides!",
        errorTxt:"Une erreur est survenue votre commande n'a pas été prise en compte...",
        closeTxt:"Annuler",
        confirmTxt:"Confirmer",
        okTxt:"OK",
    },
    krash: "Krash en cours",
}

export const loginForm = {
    email:'Adresse Email',
    emailError:"Saisissez une adresse-mail valide",
    password:"Code PIN",
    passwordError:"Saisissez un Code PIN à 4 chiffres",
    submit:"se connecter",
    duoError:"Cette combinaison Email/Code PIN n'existe pas...",
    register:"Pas de compte ?",
}

export const registerForm = {
    name: "Nom d'utilisateur (unique)",
    email:'Adresse Email',
    emailError:"Saisissez une adresse-mail valide",
    nameError:"Votre nom ne peut contenir 'Staffer'",
    password:"Code PIN",
    passwordConfirmation:"Confirmez le Code PIN",
    passwordError:"Saisissez un Code PIN à 4 chiffres",
    matchError:"Les deux mots de passe ne sont pas identiques",
    submit:"S'inscrire",
    error:"Ce nom d'utilisateur est déjà pris",
    account:"Vous avez déjà un compte ?",

}

export const orderDetail = {
    id: "Identifiant de la commande",
    amount:"Montant",
    drink:"Boisson",
    number:"n°",
    currency:"€",
    notAvailable:"Cette commande n'existe plus...",
}

export const qrScannerData = {
    title:"Scanner de QR Code",
    subtitle:"Scannez une commande pour la valider",
    idInput:"Sinon, saisissez le numéro de commande ci-dessous",
    inputLabel:"ID de commande",
    submit:"Valider",
    error:"Commande non valide",
    errorBadge: "Badge non valide",
    buttonError:"Valider à nouveau",
    success:"Commande validée avec succès",
    successBadge:"Badge validé avec succès",
    amount:"Montant",
    currency:"€",
    drink:"Boisson",
    buttonSuccess:"Valider une autre commande/badge",
}

export const footer = {
    copyright:"©TheDrinkMarket,",
    redirection:"Organisez VOTRE soirée",
}

export const dashboard = {
    title:"Tableau d'administration",
    currency:"€",
    parameters:"Paramètres de la simulation",
    n:"verres disponibles",
    gridTitle:"Vos boissons",
    parametersTitle:"Paramètres de la simulation",
    scannersTitle:"Comptes Staffeurs",
    statusTitle:"Status de la simulation",
}

export const dashboardPopUp = {
    title:"Données",
    cancel:"Annuler",
    validate:"Valider",
    info:"Une fois la simulation lancée, vous ne pourrez plus modifier ces informations. Il faudra supprimer la boisson et en créer une nouvelle.",
    name:"Nom de la boisson",
    price: "Prix moyen de la boisson en €",
    n: "Nombre de verres disponibles",
    error:"La boisson n'a pas été enregistrée, vérifiez les données que vous avez entrées"
}

export const dashboardPopUpQuantity = {
    title:"Ajouter des verres",
    cancel:"Annuler",
    validate:"Valider",
    new_n: "Nombre de verres en plus",
    error:"Vos modifications n'ont pas été enregistrées, vérifiez les données que vous avez entrées"
}

export const parameters = {
    duration:"Minutes",
    orders:"Commandes max",
    CSV:"Exporter les commandes en CSV",
    initiate:"Lancer la simulation",
    reset:"Réinitialiser la simulation",
    is_started:"La simulation tourne !",
    percentage:"Affichage des variations de prix en %",
    badgesTrue:"Activés",
    badgesFalse:"Désactivés",
    teamsTrue:"Activées",
    teamsFalse:"Désactivées",
    maxTeamEff:"Personnes max",
}

export const popUpDuration = {
    title1:"Durée avant disparition d'une commande non validée",
    title2: "(en minutes entières)",
    cancel:"Annuler",
    validate:"Valider",
    duration: "Durée en minutes",
    error:"La modification n'a pas été enregistrée, vérifiez la donnée que vous avez entrée"
}

export const popUpDeleteOrder = {
    title:"Êtes-vous sûr de vouloir supprimer cette commande ?",
    cancel:"Annuler",
    validate:"Valider",
    error:"Une erreur est survenue, la commande n'a pas été supprimée",
}

export const popUpOrders = {
    title:"Nombre maximum de commandes non-validées en parallèle",
    cancel:"Annuler",
    validate:"Valider",
    duration: "Nombre de commandes",
    error:"La modification n'a pas été enregistrée, vérifiez la donnée que vous avez entrée"
}

export const popUpMaxTeamEff = {
    title:"Nombre maximum de personnes par équipes (ne peut plus être modifié une fois la simulation lancée)",
    cancel:"Annuler",
    validate:"Valider",
    maxTeamEff: "Nombre de personnes",
    error:"La modification n'a pas été enregistrée, vérifiez la donnée que vous avez entrée"
}

export const popUpBadges = {
    title:"Activer la validation des badges par QRCode contre lot, récompenses ou goodies de votre choix",
    activate:"Activer",
    desactivate:"Désactiver",
    error:"La modification n'a pas été enregistrée, veuillez rééssayer"
}

export const popUpTeams = {
    title:"Activer un système d'équipes à suivre via le DashBoard des équipes (ne peut plus être modifié une fois la simulation lancée)",
    activate:"Activer",
    desactivate:"Désactiver",
    error:"La modification n'a pas été enregistrée, veuillez rééssayer"
}

export const popUpName = {
    title:"Renseignez le nom de votre structure",
    cancel:"Annuler",
    validate:"Valider",
    name: "Nom de la structure",
    error:"La modification n'a pas été enregistrée, vérifiez la donnée que vous avez entrée"
}


export const confirmStartOrReset = {
    titleStart:"Voulez-vous démarrer la simulation ?",
    titleReset:"Voulez-vous arrêter la simulation et rétablir les valeurs initiales ?",
    saveToCsv:"Télécharger l'historique des commandes",
    infoSaveToCsv:"(Une fois réinitialisé, toutes les commandes seront supprimées)",
    save:"TÉLÉCHARGER",
    cancel:"Annuler",
    validate:"Valider",
    error:"Une erreur est survenue, veuillez réessayer",
}

export const rank = {
    score:"Score",
    team:"Team",
    chooseTeam:"Choisir",
    waitBeginSimu:"En attente",
}

export const rankDetail = {
    description: "Remplissez les objectifs ci-dessous pour augmenter votre score et gagner des badges!",
    nb_orders:"Commandes",
    nb_drinks:"Boissons différentes",
    nb_orders_min_price:"Commandes au prix min",
    nb_orders_max_price:"Commandes au prix max",
    nb_orders_krash:"Commandes en Krash",

    current:"Nombre :"
}

export const winners = {
    title:"Meilleurs Scores",
    reset:"Rafraichir les scores",
    error:"Il n'y a pas encore assez de données sur les participants pour afficher le tableau des scores...",
    score:"Score :",
    label: [
        "Le plus d'argent dépensé",
        "Le plus gros score",
        "Le plus de commandes",
        "Le pire Trader",
        "Le meilleur Trader",
    ]
}


export const popUpConfirmDelete = {
    title:"Êtes-vous sûr de vouloir supprimer cette boisson ?",
    error:"Une erreur s'est produite, la boisson n'a pas été supprimée",
    cancel:"Annuler",
    validate:"Valider",
}

export const Staffers = {
    addStaffer:"Ajouter un staffeur",

}

export const popUpStafferDetail = {
    title:"Détail du compte Staff",
    ok: "OK",
    email:"Email",
    password:"Code PIN",
}

export const unauthorized = {
    message:"Vous n'êtes pas authorisé à accéder à cet onglet"
}

export const display = {
    tdm:"TheDrinkMarket",
    legendName:"Nom",
    legendPrice:"Prix",
    legendVariation:"Variation",
    currency:"€",
    krashAlert:"Alerte Krash!"
}


export const popUpChooseTeam = {
    title:"Choisissez ou créez une équipe",
    createTitle:"Créer une nouvelle équipe",
    joinTitle:"Rejoindre une équipe",
    createInfo:"Vous serez automatiquement ajouté dans l'équipe que vous créez et vous ne pourrez plus changer d'équipe par la suite",
    joinInfo:"Vous ne pourrez plus changer d'équipe par la suite",
    cancel:"Annuler",
    out:"Sortir",
    validate:"Valider",
    confirm:"Confirmer",
    create: "Nom de votre équipe",
    errorCreate:"Le nom d'équipe existe déjà ou vous avez dépassé la limite de 20 caractères",
    errorJoin:"L'équipe choisie est déjà pleine ou vous n'avez pas sélectionné d'équipe",
}


export const displayTeams = {
    tdm:"TheDrinkMarket",
    pageTitle:"Suivez les résulats des équipes en direct",
    totalTitle:"Depuis le début de la soirée",
    lastHourTitle:"Durant la dernière heure",
    total:{
        leftTitle:"Meilleurs Scores",
        rightTitle:"Meilleurs Traders",
    },
    lastHour:{
        leftTitle:"Total dépensé",
        rightTitle:"Meilleurs Traders",
    },
    currency:"€",
}

export const displayWinners = {
    tdm:"TheDrinkMarket",
    pageTitle:"Les personnalités à suivre ce soir...",
    totalTitle:"Depuis le début de la soirée",
    lastHourTitle:"Durant la dernière heure",
    total:{
        leftTitle:"Meilleurs Scores",
        rightTitle:"Meilleurs Traders",
    },
    lastHour:{
        leftTitle:"Total dépensé",
        rightTitle:"Meilleurs Traders",
    },
    currency:"€",
}

export const popUpPercentage = {
    title:"Afficher les variations de prix d'une boisson en % par rapport à:",
    activate:"Sa valeur précédente affichée",
    desactivate:"Sa valeur moyenne",
    error:"La modification n'a pas été enregistrée, veuillez rééssayer"
}