import React from 'react';
import ReactDOM from 'react-dom';
import tw from "twin.macro";
import { css } from "styled-components/macro";
import styled from "styled-components";
import App from './App';
import Header from "./components/header"
import {BrowserRouter as Router, Route,} from "react-router-dom";
import {Routes} from "react-router";
import {ColorWrapper, Wrapper} from "./components/templates/Layouts";
import DrinkDetail from "./pages/drinkDetail";
import Login from "./pages/login";
import Logout from "./pages/logout";
import Register from "./pages/register";
import OrderDetail from "./pages/orderDetail";
import ScanQR from "./pages/scanQR";
import Footer from "./components/footer";
import AdminDesktop from "./pages/adminDashboard";
import BadgeDetail from "./pages/badgeDetail";
import RankDetail from "./pages/rankDetail";
import Winners from "./pages/winners";
import ScrollToTop from "./scrollToTop";
import DisplayScreen from "./pages/displayScreen";
import DisplayTeams from "./pages/displayTeams";
import DisplayWinners from "./pages/displayWinners";


//const ws = new WebSocket("wss://" + window.location.hostname + "/ws/list/");
//const ws = new WebSocket("wss://localhost/ws/list/");
//const ws = new WebSocket("ws://localhost:4455/ws/list/");

let ws;


if (window.location.protocol === "http:" && window.location.port === "3000") {
    ws = new WebSocket("ws://" + window.location.hostname + ":4455/ws/list/");
} else if (window.location.protocol === "http:" && window.location.hostname.includes("192.168.")) {
    ws = new WebSocket("ws://" + window.location.hostname + "/ws/list/");
} else {
    ws = new WebSocket("wss://" + window.location.hostname + "/ws/list/");
}

const routing = (
    <Router>
        <React.StrictMode>
            <ColorWrapper>
                <Wrapper>
                    <Header key={window.location.pathname}/>
                    <ScrollToTop />
                    <Routes>
                        <Route path="/" element={<App websocket={ws}/>}/>
                        <Route path="/conso/:id" element={<DrinkDetail websocket={ws}/>}/>
                        <Route path="/orders/:id" element={<OrderDetail/>}/>
                        <Route path="/badge/:id" element={<BadgeDetail/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/register" element={<Register/>}/>
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="/scan" element={<ScanQR/>}/>
                        <Route path="/grades" element={<RankDetail/>}/>
                        <Route path="/displayWinners" element={<DisplayWinners/>}/>
                        <Route path="/admin" element={<AdminDesktop/>}/>
                        <Route path="/displayGraph" element={<DisplayScreen websocket={ws}/>}/>
                        <Route path="/displayTeams" element={<DisplayTeams/>}/>
                    </Routes>
                </Wrapper>
                <Footer key={window.location.pathname}/>
            </ColorWrapper>
        </React.StrictMode>
    </Router>
)


ReactDOM.render(
  routing,
  document.getElementById('root')
);

