import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {Subwrapper} from "../components/templates/Layouts";
import {Title} from "../components/templates/Titles";
import {qrScannerData} from "../data";
import styled from "@emotion/styled";
import axiosInstance from "../axios";
import {QrReader} from "@blackbox-vision/react-qr-reader";
import {EyeIcon, EyeOffIcon} from "@heroicons/react/outline";
import Validated from "../components/scanQR/validated";
import Error from "../components/scanQR/error";
import ValidatedBadge from "../components/scanQR/validatedBadge";
import ErrorBadge from "../components/scanQR/errorBadge";
import ScanSimulationButton from "../components/scanQR/simulationButton";
import {checkScanner} from "../checkUser";
import Unauthorized from "../components/unauthorized";

const QRTitle = tw(Title)`mt-4`;
const BodyContainer = tw.div`my-8 bg-palette1-300 p-4 rounded-xl text-center`;
const SubTitle = tw.div`text-center font-light text-sm text-palette1-100`;
const ScanContainer = styled.div`
  ${tw`rounded-xl my-4 px-2`};
  
  img {
    ${tw`w-full rounded-xl shadow-lg`}
  }
  
  svg {
    ${tw`absolute w-96 h-96 text-palette1-100`}
  }
`;


const ColumnsContainer = tw.div`flex flex-row my-4`;
const LeftColumn = tw.div`relative flex items-center w-9/12 max-w-none text-left`;
const RightColumn = tw.div`relative flex-1 flex justify-center items-center`;
const EyeContainer = tw.div`w-8 h-8 flex text-black justify-center items-center rounded-full`;

const IdInput = tw.input`mt-2 w-9/12 bg-palette1-400 px-4 font-thin text-lg text-black py-3 border-solid border-0 border-b-2 border-l-2 border-palette1-100 rounded-xl leading-tight hocus:border-palette1-500 hocus:outline-none transition duration-300 hocus:shadow-lg`;
const Submit = tw.button`w-9/12 px-3 mt-8 mb-4 py-4 rounded-full border-0 text-palette1-100 uppercase font-hairline text-lg bg-palette1-400 shadow-lg transition duration-500 hocus:bg-palette1-100 hocus:text-palette1-400`;


export default function ScanQR() {

    const initialFormData = Object.freeze({
        order_id: '',
    });

    const [formData, updateFormData] = useState(initialFormData);

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim(),
        });
    };

    //Variables to handle the scan
    const delay = 300;
    const [isMounted, SetIsMounted] = useState(true)
    const [data, setData] = useState('No result');
    const [dataBadge, setDataBadge] = useState('No result');
    const [badinput, SetBadinput] = useState(false)


    //To handle scan validation or unvalidation

    // Dialog if command is deleted or has already been validated
    const [error, setError] = useState(false);
    const handleErrorClose = () => {
        setError(false);
        SetIsMounted(true);
    };
    // Dialog if badge is deleted or has already been validated
    const [errorBadge, setErrorBadge] = useState(false);
    const handleErrorBadgeClose = () => {
        setErrorBadge(false);
        SetIsMounted(true);
    };

    // Dialog if command has been validated
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
        SetIsMounted(true);
    };

    // Dialog if badge has been validated
    const [openBadge, setOpenBadge] = useState(false);
    const handleBadgeClose = () => {
        setOpenBadge(false);
        SetIsMounted(true);
    };


    //Submit function
    const handleSubmit = (e) => {
        e.preventDefault();
        if ( /^\d+$/.test(formData.order_id) === false ){
            SetBadinput(true)
        }
        else{
            SetIsMounted(false);
            setData(formData.order_id);
            axiosInstance.put('/orders/' + formData.order_id + '/validate/', {distributed:true} ).then((res) => {
                //Afficher bannière commande validée
                //console.log("data posted");
                setOpen(true);

            }).catch((error) => {
                //Afficher " cette commande n'existe plus "
                if (error.response.status === 403 || error.response.status === 404) {
                    //console.log("403");
                    setError(true);
                }
            })
        }
    }


    //Function when scan is detected
    function handleResult(text) {
        //console.log(text);
        try {
            const data_scan = JSON.parse(text)
            //console.log(data);
            //console.log(data.id);
            if (data_scan.id) {
                setData(data_scan.id);
                const id = data_scan.id
                //console.log(id);
                SetIsMounted(false);
                axiosInstance.put('/orders/' + id.toString() + '/validate/', {distributed:true} ).then((res) => {
                    //Afficher bannière commande validée
                    //console.log("data posted");
                    setOpen(true);

                }).catch((error) => {
                    //Afficher " cette commande n'existe plus "
                    if (error.response.status === 403 || error.response.status === 404) {
                        //console.log("403 ou 404");
                        setError(true);
                    }
                })

            }
            else if (data_scan.badge_id){
                setDataBadge(data_scan)
                SetIsMounted(false);
                axiosInstance.post('badges/validate_badge', data_scan).then((res) => {
                    //Afficher bannière commande validée
                    //console.log("data posted");
                    setOpenBadge(true);

                }).catch((error) => {
                    //Afficher " badge invalide "
                    if (error.response.status === 403 || error.response.status === 404 || error.response.status === 400) {
                        //console.log("403 ou 404");
                        setErrorBadge(true);
                    }
                })

            }
        } catch (e) {
            //Si scan un QR Code qui n'a rien à voir
            //console.log("bad_request")
        }
    }



    const [eye, setEye] = useState(false);
    //Function to handle eye
    function handleClickEye() {
        if(eye){
            setEye(false);
        }
        else{
            setEye(true);
        }
    }

    if (!checkScanner()) {
        return <Unauthorized/>
    }
    else {

        return (
            <Subwrapper>
                <QRTitle>
                    {qrScannerData.title}
                </QRTitle>
                {isMounted &&
                    <BodyContainer>
                        <SubTitle>{qrScannerData.subtitle}</SubTitle>
                        <ScanContainer>
                            <QrReader
                                constraints={{facingMode: 'environment',}}
                                videoStyle={{objectFit: "cover", zIndex: 0, borderRadius: 15}}
                                scanDelay={delay}
                                onResult={(result, error) => {
                                    if (!!result) {
                                        handleResult(result?.text);
                                    }

                                    if (!!error) {
                                        //console.info(error);
                                    }
                                }}
                                style={{width: '100%'}}
                            />
                        </ScanContainer>
                        <ColumnsContainer>
                            <LeftColumn>
                                <SubTitle>{qrScannerData.idInput}</SubTitle>
                            </LeftColumn>
                            <RightColumn>
                                <EyeContainer onClick={handleClickEye}>
                                    {eye ? <EyeIcon/> : <EyeOffIcon/>}
                                </EyeContainer>
                            </RightColumn>
                        </ColumnsContainer>
                        {eye &&
                            <form>
                                <IdInput
                                    required
                                    name="order_id"
                                    placeholder={qrScannerData.inputLabel}
                                    type="number"
                                    id="order_id"
                                    inputProps={{inputMode: 'numeric'}}
                                    onChange={handleChange}
                                />
                                <Submit type="submit" onClick={handleSubmit}>
                                    {qrScannerData.submit}
                                </Submit>
                            </form>
                        }
                    </BodyContainer>
                }
                {open &&
                    <Validated id={data} qrScannerData={qrScannerData} handlesSubmit={handleClose}/>
                }
                {openBadge &&
                    <ValidatedBadge qrScannerData={qrScannerData} handlesSubmit={handleBadgeClose}/>
                }
                {error &&
                    <Error qrScannerData={qrScannerData} handlesSubmit={handleErrorClose}/>
                }
                {errorBadge &&
                    <ErrorBadge qrScannerData={qrScannerData} handlesSubmit={handleErrorBadgeClose}/>
                }
                {checkScanner() &&
                    <ScanSimulationButton/>
                }

            </Subwrapper>
        )
    }
}