import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import styled from "styled-components";
import axiosInstance from "../axios";
import {display as displayData} from "../data";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {ArrowsExpandIcon} from "@heroicons/react/solid";

import {Title} from "../components/templates/Titles";
import {useParams} from "react-router";
import {ResponsiveContainer, LineChart, Legend, CartesianGrid, XAxis, YAxis, Tooltip, Line} from "recharts";
const Container = tw.div`h-screen max-h-screen bg-palette1-100`;
const DashboardContainer = tw.div`flex-col p-2 h-full`

const Header = tw.div`flex rounded-xl bg-gray-900 h-1/12`
const TDM = tw.div`text-palette1-200 text-2xl font-bold w-2/12 self-center text-right`
const X = tw.div`text-palette1-400 text-3xl font-bold w-1/12 self-center text-center`
const BarName = tw.div`text-palette1-200 text-2xl font-bold w-3/12 self-center text-left`
//Cheapest section
const CheapestContainer = tw.div`flex flex-grow w-6/12 text-center`
const Cheap = tw.div`flex-grow flex text-center self-center`
const Rank = tw.div`text-4xl font-semibold self-center text-palette1-500`
const Name = tw.div`text-xl flex-grow self-center truncate text-palette1-400`
const FullScreenContainer = tw.div`cursor-pointer flex self-center text-palette1-200 justify-center items-center w-10 h-10 mr-3`


const DashboardBody = tw.div`flex flex-row items-stretch mt-2 h-11/12`

const CurvesContainer = tw.div`w-8/12 rounded-xl bg-palette1-300 mb-6 bg-gray-900 p-4 mr-2`


const LegendContainer = tw.div`p-4 flex-grow rounded-xl text-palette1-400 bg-palette1-300 mb-6 bg-gray-900 justify-center items-center`
const KrashAlert = tw.div`rounded-xl p-4 text-center text-black font-semibold text-2xl bg-palette1-200`
const ListContainer = tw.div``
const ListItem = tw.div`flex p-1 px-2 rounded-xl`
const ItemColor = tw.div`flex justify-center items-center mr-4`

const ItemName = tw.div`flex-grow truncate w-6/12`
const ItemPrice = tw.div`flex-grow text-right w-3/12`
const ItemGrowth = tw.div`flex-grow text-right w-3/12`

const TextContainer = tw.div``

const color_list = [
    "#FF0000",
    "#FFFF00",
    "#0080FF",
    "#40FFFF",
    "#FF8000",
    "#FF80FF",
    "#40C0FF",
    "#40C000",
    "#C000C0",
    "#8000FF",
    "#FF8080",
    "#C0FF00",
    "#FFFFC0",
    "#800000",
    "#808000",
    "#008000",
    "#80C080",
    "#008080",
    "#0040FF",
    "#8080FF",
]

//To calculate the percentage of diminution depending on last value
function Percentage(props) {

    //console.log("percentage", props.item.price_hist);

    if (props.item.price_hist === []) return <></>
    else {
        if(props.item.price_hist.length === 1) {return <TextContainer css={tw`text-palette1-400`} >(- 0%)</TextContainer>}
        else {
            // Give shift percentage
            const variation = ((Math.abs(props.item.price_hist[props.item.price_hist.length - 2]-props.item.price_hist[props.item.price_hist.length - 1])/props.item.price_hist[props.item.price_hist.length - 2])*100).toFixed(2)
            if (props.item.is_going_down) {
                return <TextContainer css={tw`text-green-400`} >-{variation}%</TextContainer>
            }
            else{
                return <TextContainer css={tw`text-red-400`} >{variation}%</TextContainer>
            }
        }
    }
}

function ItemsLegend(props) {

    return(
        <ListContainer>
            {checkIfKrash(props.beers) &&
                <KrashAlert>
                    {displayData.krashAlert}
                </KrashAlert>
            }
            <ListItem key={0} css={tw`mb-3 text-palette1-200`}>
                <ItemName>
                    {displayData.legendName}
                </ItemName>
                <ItemPrice>
                    {displayData.legendPrice}
                </ItemPrice>
                <ItemGrowth>
                    {displayData.legendVariation}
                </ItemGrowth>
            </ListItem>
            {props.beers.map((beer, i)=>{
                //console.log(beer)
                //console.log(props.varPercentagePrecedent)
                return(
                    <ListItem key={i+1} css={beer.is_crash === false ? (i%2 === 0 ? tw`` : tw`bg-gray-800`) : tw`bg-palette1-100 text-black -mx-4 px-4 rounded-none`}>
                        <ItemColor>
                            <div style={{width:"10px", height:"10px", borderRadius:"50%", backgroundColor:color_list[props.idOrder.findIndex((elem)=>elem === beer.id)]}}>

                            </div>
                        </ItemColor>
                        <ItemName>
                            {beer.name}
                        </ItemName>
                        <ItemPrice>
                            {beer.price_hist.slice(-1)} {displayData.currency}
                        </ItemPrice>
                        {props.varPercentagePrecedent === false &&
                            <ItemGrowth css={(beer.price_hist[beer.price_hist.length - 1]-beer.average_price) < 0 ? tw`text-green-400` : tw`text-red-400`}>
                                {(((beer.price_hist[beer.price_hist.length - 1]-beer.average_price)/beer.average_price)*100).toFixed(2)}{"%"}
                            </ItemGrowth>
                        }
                        {props.varPercentagePrecedent === true &&
                            <ItemGrowth>
                                <Percentage item={beer} />
                            </ItemGrowth>
                        }

                    </ListItem>
                )
            })}

        </ListContainer>
    )
}

function Graph(props) {

    function renderLines () {
        const lines = props.beers.map((beer,i) => (
            <Line
                key={i}
                type="monotone"
                dataKey={beer.name}
                dot={false}
                stroke={color_list[props.idOrder.findIndex((elem)=>elem === beer.id)]}
                strokeWidth={3}
                isAnimationActive={false}
            />
        ));
        return lines;
    }

    const formatYAxis = (tickItem) => {
        const result = tickItem.toString() + displayData.currency
        return result;

    }

    return(
        <ResponsiveContainer width="100%" height="100%" >

            <LineChart data={props.data}
                       margin={{ top: 20, right: 20, left: -5, bottom: 0 }}
            >
                <CartesianGrid vertical={false} strokeDasharray="16 6" stroke={"#C4C4C4"}/>
                <XAxis dataKey={"hour"} tickCount={0} stroke={"#C4C4C4"}/>
                {renderLines()}
                <YAxis tickFormatter={formatYAxis} stroke={"#C4C4C4"}/>
                <Legend margin={{top:20}}/>
            </LineChart>
        </ResponsiveContainer>
    )
}

function checkIfKrash(beers) {
    return beers.some((beer) => beer.is_crash === true);
}

function Cheapest(props) {

    return(
        <CheapestContainer>
            {props.beers.map((beer,i)=> {
                if (i<=2) {
                    return(
                        <Cheap key={i}>
                            <Rank>
                                #{i+1}
                            </Rank>
                            <Name>
                                {beer.name}
                            </Name>
                        </Cheap>
                    )
                }
            })

            }
        </CheapestContainer>
    )
}


export default function DisplayScreen(props) {

    const [beers, setBeers] = useState(null);
    const [idOrder,setIdOrder] = useState(null)
    const handle = useFullScreenHandle();
    const [name, setName] = useState(null)
    const [varPercentagePrecedent,setVarPercentagePrecedent] = useState(null);

    useEffect( ()=> {
        axiosInstance.get('/beers/short/').then((res) => {
            const temp = res.data;
            setBeers(temp);
            //console.log("Beers",temp)
        }).catch((error) => {
            // Handle error here
        })

        const interval = setInterval(() => {
            axiosInstance.get('/beers/short/').then((res) => {
                const temp = res.data;
                setBeers(temp);
                //console.log("Beers",temp)
            }).catch((error) => {
                // Handle error here
            })
        }, 60000);
        return () => clearInterval(interval);

    }, [setBeers]);

    useEffect( ()=>{
        if (beers !== null) {
            setIdOrder(beers.map(beer => beer.id).sort());
        }
    },[beers])

    useEffect( ()=>{
            axiosInstance.get('/parameters/get_name').then((res)=>{
                const temp = res.data;
                setName(temp.name);
            }).catch((error)=>{
                // Handle error here
            })
        },
        [setBeers]);

    useEffect( ()=>{
            axiosInstance.get('/parameters/get_var_percentage_precedent').then((res)=>{
                const temp = res.data;
                setVarPercentagePrecedent(temp.var_percentage_precedent);
            }).catch((error)=>{
                // Handle error here
            })
        },
        [setVarPercentagePrecedent]);

    //console.log("beers", beers)

    //Updating drinks with websocket
    const ws = props.websocket;
    //console.log(ws)
    //Web socket
    // Ne pas encadrer la fonction onmessage par un use effect sinon pour une raison obscure seulement certain alcools sont update en temp réel...
    ws.onmessage = function (e) {
        //console.log(typeof (e.data));
        let data = JSON.parse(e.data);
        //console.log("data",data);
        //console.log(data.id)
        if (data && beers !== null) {
            setBeers(
                [...beers].map((beer) => {
                    if (beer.id === data.id) {
                        //console.log("true")
                        return ({
                            id: data.id,
                            n: data.n,
                            name: data.name,
                            average_price:data.average_price,
                            price_hist: data.price_hist,
                            date_hist: data.date_hist,
                            is_pinned: data.is_pinned,
                            is_going_down: data.is_going_down,
                            is_crash: data.is_crash,
                        })
                    }
                    else return beer;
                })
            )
        }
    }

    {/*
    if(beers === null) {
        return(
                <Container>
                    <p>Bitobite</p>
                </Container>
        )
    }
    */}

    return(
        <FullScreen handle={handle}>
            <Container>
                <DashboardContainer>
                    <Header>
                        <TDM>
                            {displayData.tdm}
                        </TDM>
                        <X>
                            X
                        </X>
                        <BarName>
                            {name}
                        </BarName>
                        {beers !== null &&
                            <Cheapest beers={ beers.sort((a, b) => a.price_hist.slice(-1) - b.price_hist.slice(-1)) }/>
                        }
                        <FullScreenContainer onClick={handle.enter}>
                            <ArrowsExpandIcon/>
                        </FullScreenContainer>
                    </Header>
                    <DashboardBody>
                        <CurvesContainer>
                            {beers!==undefined && beers!==null && idOrder!== null &&
                                <Graph
                                    beers={beers}
                                    idOrder={idOrder}
                                    data={Object.assign( beers[0].price_hist.map((price, i) => ( {...beers.reduce((acc, beer) => ({ ...acc, [beer.name]: beer.price_hist[i] }), {}), ...{hour:beers[0].date_hist[i].substring(11,16)}} ) ))}
                                />
                            }
                        </CurvesContainer>
                        <LegendContainer>
                            {beers !== null && idOrder!== null &&
                                <ItemsLegend varPercentagePrecedent={varPercentagePrecedent} idOrder={idOrder} beers={beers}/>
                            }
                        </LegendContainer>
                    </DashboardBody>
                </DashboardContainer>
            </Container>
        </FullScreen>
    )
}