import React, {useRef, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {XIcon} from "@heroicons/react/solid";
import {popUpConfirmDelete as data} from "../../data";
import {useOutsideClose} from "../templates/messagePopUp";


const Wrapper = tw.div`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none`;
const Container = tw.div`relative my-6 flex-grow mx-auto max-w-screen-sm`;
const Content = tw.div`mx-2 border-0 p-3 px-6 rounded-xl shadow-2xl relative flex flex-col bg-palette1-200 outline-none`;

const Header = tw.div`flex items-center justify-between`;
const HeaderTitle = tw.div`pl-1 text-xl sm:text-2xl font-semibold text-black`;
const HeaderClose = tw.div`h-8 w-8 flex p-2 text-palette1-400 justify-center items-center rounded-full cursor-pointer`;

const ErrorLabel = tw.p`text-center mt-8 mb-4 text-red-500 text-base font-medium`;

const Footer =tw.div`flex mt-8 flex-row justify-center mb-2`;
const TextButton = tw.div`uppercase p-2 rounded-xl bg-palette1-400 text-black font-semibold text-base cursor-pointer border-2 border-solid border-palette1-400 transition duration-500 hocus:border-palette1-500 hocus:shadow-lg`;




export default function PopupValidateDelete(props) {

    //Show error message if bad request
    const [error, setError] = useState(false)

    const handleResetError = () => {
        setError(false);
    }


    const wrapperRef = useRef(null);
    useOutsideClose(wrapperRef, props.handlePopClose);

    return (
        <Wrapper>
            <Container>
                <Content ref={wrapperRef}>
                    <Header>
                        <HeaderTitle>
                            {data.title}
                        </HeaderTitle>
                        <HeaderClose onClick={props.handlePopClose}>
                            <XIcon/>
                        </HeaderClose>
                    </Header>
                    <ErrorLabel css={error ? tw`block` : tw`hidden`}>
                        {data.error}
                    </ErrorLabel>
                    <Footer>
                        <TextButton onClick={props.handlePopClose} css={tw`mr-3`}>
                            {data.cancel}
                        </TextButton>
                        <TextButton onClick={() => {props.handleConfirm(props.beer)}} css={tw`ml-3`}>
                            {data.validate}
                        </TextButton>
                    </Footer>
                </Content>
            </Container>
        </Wrapper>
    )
}