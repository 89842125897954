import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import { motion } from "framer-motion";
import styled from "styled-components";
import {pinList as data} from "../../data";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {Title} from "../templates/Titles";
import {useNavigate} from "react-router"; //eslint-disable-line

export const PinTitle = tw(Title)`flex flex-row transition duration-1000`;
const LeftColumn = tw.div`relative flex items-center w-9/12 max-w-none text-left`;
const RightColumn = tw.div`relative flex-1 flex justify-end items-center`;
const Text = tw.div``;
const DrinkToggleIcon = motion(styled.span`
  ${tw`transition duration-300 flex mr-6 justify-center items-center rounded-full`}
  svg {
    ${tw`w-8 h-8`}
  }
`);

const ListSection = motion(tw.div`transition duration-200`);
const List = tw.ol`relative pl-0`;
const ListItem = tw.li`list-none py-4 my-8 px-6 rounded-xl text-lg shadow-md flex flex-row`;
const ListText = tw.div`relative flex w-9/12 font-bold text-left self-center text-palette1-400`;
const ListPrice = tw.div`relative flex-1 font-medium text-right self-center`;
const SoldOut = tw.div`uppercase relative flex-1 text-black text-base font-medium text-right self-center`;


export default function PinList(props) {

    //console.log(props.ids);
    const navigate = useNavigate();
    function handleClick(item) {
        const strParam = item.id.toString();
        if (item.n > 0) {
            navigate('/conso/' + strParam, {state: {item: item}})
        }
    }


    return(
        <>
            <PinTitle onClick={props.pinFunction}>
                <LeftColumn>
                    <Text>{data.title}</Text>
                </LeftColumn>
                <RightColumn>
                    <DrinkToggleIcon
                        variants={{
                            collapsed: { rotate: 0 },
                            open: { rotate: -180 }
                        }}
                        initial="collapsed"
                        animate={props.pinned ? "open" : "collapsed"}
                        transition={{ duration: 0.1, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <ChevronDownIcon />
                    </DrinkToggleIcon>
                </RightColumn>
            </PinTitle>
            <ListSection
                variants={{
                    open: { opacity: 1, height: "auto", pointerEvents:"auto"},
                    collapsed: { opacity: 0, height: 0, pointerEvents:"none"}
                }}
                initial="collapsed"
                animate={props.pinned ? "open" : "collapsed"}
                transition={{ duration: 0.2, ease: [0.04, 0.23, 0.62, 0.98] }}
            >

                <List>
                    {props.liveItems.map((item)=>{

                        if (props.ids.includes(item.id)) {
                            return (
                                <ListItem key={item.id} onClick={() => {handleClick(item)}} css={(item.n <= 0 ? tw`bg-gray-300 transition duration-500` : (item.is_going_down === true ? tw`bg-green-700 transition duration-500` : tw`bg-red-700 transition duration-500`))}>
                                    <ListText
                                        css={item.n <= 0 ? tw`text-black w-7/12` : tw``}
                                    >
                                        {item.name}
                                    </ListText>
                                    {item.n > 0 ?
                                        <ListPrice
                                            css={item.is_going_down === true ? tw`text-palette1-400` : tw`text-palette1-400`}>
                                            {item.price_hist[item.price_hist.length - 1]} {data.currency}
                                        </ListPrice>
                                        :
                                        <SoldOut>
                                            {data.soldout}
                                        </SoldOut>
                                    }
                                </ListItem>
                            )
                        }
                        })
                    }
                </List>
            </ListSection>
        </>
    )
}