import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import styled from "styled-components";
import axiosInstance from "../axios";
import {displayTeams as displayData} from "../data";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {ArrowsExpandIcon} from "@heroicons/react/solid";

const Container = tw.div`h-screen max-h-screen bg-palette1-100`;
const DashboardContainer = tw.div`flex-col p-2 h-full`

const Header = tw.div`flex rounded-xl bg-gray-900 h-1/12`
const TDM = tw.div`text-palette1-200 text-2xl font-bold w-2/12 self-center text-right`
const X = tw.div`text-palette1-400 text-3xl font-bold w-1/12 self-center text-center`
const BarName = tw.div`text-palette1-200 text-2xl font-bold w-3/12 self-center text-left`
//Page title section
const PageTitle = tw.div`flex flex-grow w-6/12 text-center self-center text-xl font-semibold text-palette1-400`
const FullScreenContainer = tw.div`cursor-pointer flex self-center text-palette1-200 justify-center items-center w-10 h-10 mr-3`

const DashboardBody = tw.div`flex flex-row items-stretch mt-2 h-11/12`
const LeftContainer = tw.div`flex-col w-6/12 rounded-xl bg-palette1-300 mb-6 bg-gray-900 p-4 mr-1 items-center`
const RightContainer = tw.div`flex-col w-6/12 p-4 flex-grow ml-1 rounded-xl text-palette1-400 bg-palette1-300 mb-6 bg-gray-900 items-center`

const ContainerTitle = tw.div`rounded-xl border-solid border-palette1-500 mb-2 p-2 text-center text-xl font-semibold text-palette1-400`
const ContainerBody = tw.div`flex-grow flex flex-row items-stretch`
const LeftColumn = tw.div`w-6/12`
const RightColumn = tw.div`flex-grow`
const ColumnTitle = tw.div`text-center text-base mt-2 mb-3 font-normal text-palette1-200`

const ListContainer = tw.div`mt-2 w-full flex flex-col h-11/12`;
const ListItem = tw.div`flex items-center text-base bg-palette1-400 p-1 mx-2 mb-3 rounded-xl border-solid border-2 border-palette1-200`
const ItemRank = tw.div`w-1/12 font-semibold text-center rounded-xl bg-palette1-100 text-palette1-400 p-1 mr-2`
const ItemName = tw.div`flex-grow text-left font-semibold text-black`
//const ItemEff = tw.div`flex-grow mx-2 text-center text-black`
const ItemDisplay = tw.div`w-3/12 text-right text-palette1-100 font-semibold`


function List(props) {
    const teams = props.teams
    const display = props.display

    return(
        <ListContainer>
            {teams.map((team, i)=>{

                return(
                    <ListItem css={!display.includes("trading") ? tw`` : tw`bg-palette1-500 border-palette1-500`} key={i}>
                        <ItemRank css={!display.includes("trading") ? tw`` : tw`bg-palette1-400 text-palette1-500`}>
                            {"#" + (i+1).toString()}
                        </ItemRank>
                        <ItemName css={!display.includes("trading") ? tw`` : tw`text-palette1-400 text-center`}>
                            {team.name}
                        </ItemName>
                        {!display.includes("trading") &&
                            <ItemDisplay>
                                {team[display]} {display.includes("get_last_hour_money_spent") ? displayData.currency : ""}
                            </ItemDisplay>
                        }
                    </ListItem>
                )
            })}
        </ListContainer>
    )

}


export default function DisplayTeams(props) {

    const handle = useFullScreenHandle();
    const [name, setName] = useState(null)


    const [teams, setTeams] = useState(null)
    //console.log(teams)

    useEffect( ()=>{
            axiosInstance.get('/parameters/get_name').then((res)=>{
                const temp = res.data;
                setName(temp.name);
            }).catch((error)=>{
                // Handle error here
            })
        },
        [setName]);

    useEffect( ()=>{
            axiosInstance.get('/teams/display').then((res)=>{
                const temp = res.data;
                setTeams(temp);
            }).catch((error)=>{
                // Handle error here
            })
            const interval = setInterval(() => {
                axiosInstance.get('/teams/display').then((res)=>{
                    const temp = res.data;
                    setTeams(temp);
                }).catch((error)=>{
                    // Handle error here
                })
            }, 30000);
            return () => clearInterval(interval);
        },
        [setTeams]);


    return(
        <FullScreen handle={handle}>
            <Container>
                <DashboardContainer>
                    <Header>
                        <TDM>
                            {displayData.tdm}
                        </TDM>
                        <X>
                            X
                        </X>
                        <BarName>
                            {name}
                        </BarName>
                        <PageTitle>
                            {displayData.pageTitle}
                        </PageTitle>
                        <FullScreenContainer onClick={handle.enter}>
                            <ArrowsExpandIcon/>
                        </FullScreenContainer>
                    </Header>
                    <DashboardBody>
                        <LeftContainer>
                            <ContainerTitle>
                                {displayData.totalTitle}
                            </ContainerTitle>
                            <ContainerBody>
                                <LeftColumn>
                                    <ColumnTitle>
                                        {displayData.total.leftTitle}
                                    </ColumnTitle>
                                    {teams !== null &&
                                        <List
                                            teams={teams.map(team => {return {...team}}).sort((a,b) => b.get_total_score - a.get_total_score).slice(0, 10)}
                                            display="get_total_score"
                                        />
                                    }
                                </LeftColumn>
                                <RightColumn>
                                    <ColumnTitle>
                                        {displayData.total.rightTitle}
                                    </ColumnTitle>
                                    {teams !== null &&
                                        <List
                                            teams={teams.map(team => {return {...team}}).sort((a,b) => b.get_total_trading_perf - a.get_total_trading_perf).slice(0, 10)}
                                            display="get_total_trading_perf"
                                        />
                                    }
                                </RightColumn>
                            </ContainerBody>
                        </LeftContainer>
                        <RightContainer>
                            <ContainerTitle>
                                {displayData.lastHourTitle}
                            </ContainerTitle>
                            <ContainerBody>
                                <LeftColumn>
                                    <ColumnTitle>
                                        {displayData.lastHour.leftTitle}
                                    </ColumnTitle>
                                    {teams !== null &&
                                        <List
                                            teams={teams.map(team => {return {...team}}).sort((a,b) => b.get_last_hour_money_spent - a.get_last_hour_money_spent).slice(0, 10)}
                                            display="get_last_hour_money_spent"
                                        />
                                    }
                                </LeftColumn>
                                <RightColumn>
                                    <ColumnTitle>
                                        {displayData.lastHour.rightTitle}
                                    </ColumnTitle>
                                    {teams !== null &&
                                        <List
                                            teams={teams.map(team => {return {...team}}).sort((a,b) => b.get_last_hour_trading_perf - a.get_last_hour_trading_perf).slice(0, 10)}
                                            display="get_last_hour_trading_perf"
                                        />
                                    }
                                </RightColumn>
                            </ContainerBody>
                        </RightContainer>
                    </DashboardBody>
                </DashboardContainer>
            </Container>
        </FullScreen>
    )
}