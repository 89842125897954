import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {Subwrapper} from "../components/templates/Layouts";
import axiosInstance from "../axios";
import BadgeSlider from "../components/badgeList/badgeSlider";
import Rank from "../components/rankContainer/rank";
import {rankDetail as data} from "../data";
import {Title} from "../components/templates/Titles";

export const ChallengeTitle = tw(Title)`my-2 text-xl flex flex-row pr-6`;
const LeftColumn = tw.div`relative flex items-center w-10/12 max-w-none text-left`;
const RightColumn = tw.div`relative flex-1 text-right`;
const DescriptionMessage = tw.div`border-solid border-palette1-500 rounded-xl p-4 text-palette1-500 text-center text-base font-bold my-4`;

export default function RankDetail(props) {

    const [badges, setBadges] = useState(null);
    const [challenges, setChallenges] = useState(null);
    const [name, setName] = useState("")
    const [score, setScore] = useState(0);
    const [rank, setRank] = useState(0);
    const [useOfBadges, setUseOfBadges] = useState(null);

    //Fetching badgeList
    useEffect( () => {
        axiosInstance.get('/badges').then((res)=>{
            const temp = res.data;
            //console.log(temp)
            setBadges(temp);
        }).catch((error)=>{
            // Handle error here
        })
    }, [setBadges]);

    //Fetching progress in challenges
    useEffect( () => {
        axiosInstance.get('user/get_challenges_detail').then((res)=>{
            const temp = res.data;
            //console.log(temp);
            setChallenges(temp);
        }).catch((error)=>{
            // Handle error here
        })
    }, [setChallenges]);

    //Fetching Score & Rank
    useEffect( ()=> {
            axiosInstance.get("user/get_score").then((res) => {
                setScore(res.data.score)
                setName(res.data.name)
                setUseOfBadges(res.data.badges_validation)
            })

        },
        [setScore])

    useEffect( () => {
            if (score > 0 && score < 300) {
                setRank (1);
            }
            else if (score >= 300 && score < 600) {
                setRank (2);
            }
            else if (score >= 600 && score < 900) {
                setRank (3);
            }
            else if (score >= 900 && score < 1200) {
                setRank (4);
            }
            else if (score >= 1200 && score < 1500) {
                setRank (5);
            }
            else if (score >= 1500 && score < 1800) {
                setRank (6);
            }
            else if (score >= 1800 && score < 2100) {
                setRank (7);
            }
            else if (score >= 2100 && score < 2400) {
                setRank (8);
            }
            else if (score >= 2400 && score < 2700) {
                setRank (9);
            }
            else if (score >= 2700) {
                setRank (10);
            }
            //console.log(rank)
        },
        [score])



    return(
        <Subwrapper>
            <Rank is_main={true}/>
            <DescriptionMessage>
                {data.description}
            </DescriptionMessage>

                {challenges !== null && badges !== null && useOfBadges !== null &&
                    <>
                        <ChallengeTitle>
                            <LeftColumn>
                                {data.nb_orders}
                            </LeftColumn>
                            <RightColumn>
                                {challenges.nb_orders}
                            </RightColumn>
                        </ChallengeTitle>
                        {useOfBadges === true &&
                            <BadgeSlider badges={badges.filter(function (badge) {
                                return !!badge.name.includes("nb_orders_lvl_")
                            }).map((badge) => {
                                return (badge)
                            })}/>
                        }

                        <ChallengeTitle>
                            <LeftColumn>
                                {data.nb_drinks}
                            </LeftColumn>
                            <RightColumn>
                                {challenges.nb_drinks}
                            </RightColumn>
                        </ChallengeTitle>
                        {useOfBadges === true &&
                            <BadgeSlider badges={badges.filter(function (badge) {
                                return !!badge.name.includes("nb_drinks_lvl_")
                            }).map((badge) => {
                                return (badge)
                            })}/>
                        }

                        <ChallengeTitle>
                            <LeftColumn>
                                {data.nb_orders_min_price}
                            </LeftColumn>
                            <RightColumn>
                                {challenges.nb_orders_min_price}
                            </RightColumn>
                        </ChallengeTitle>
                        {useOfBadges === true &&
                            <BadgeSlider badges={badges.filter(function (badge) {
                                return !!badge.name.includes("nb_orders_min_price_lvl_")
                            }).map((badge) => {
                                return (badge)
                            })}/>
                        }
                        <ChallengeTitle>
                            <LeftColumn>
                                {data.nb_orders_max_price}
                            </LeftColumn>
                            <RightColumn>
                                {challenges.nb_orders_max_price}
                            </RightColumn>
                        </ChallengeTitle>
                        {useOfBadges === true &&
                            <BadgeSlider badges={badges.filter(function (badge) {
                                return !!badge.name.includes("nb_orders_max_price_lvl_")
                            }).map((badge) => {
                                return (badge)
                            })}/>
                        }

                        <ChallengeTitle>
                            <LeftColumn>
                                {data.nb_orders_krash}
                            </LeftColumn>
                            <RightColumn>
                                {challenges.nb_orders_krash}
                            </RightColumn>
                        </ChallengeTitle>
                        {useOfBadges === true &&
                            <BadgeSlider badges={badges.filter(function (badge) {
                                return !!badge.name.includes("nb_orders_krash_lvl_")
                            }).map((badge) => {
                                return (badge)
                            })}/>
                        }
                    </>
                }
        </Subwrapper>
    )
}