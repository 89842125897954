import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {useNavigate} from "react-router";
import axiosInstance from "../axios";


export default function Logout() {

    const navigate = useNavigate();

    if (process.env.REACT_APP_IS_DEMO === "TRUE") {
        window.location.replace('/login');
    }

    useEffect(() => {
        const response = axiosInstance.post('user/logout/blacklist/', {
            refresh_token: localStorage.getItem('refresh_token'),
        });
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('is_staff');
        axiosInstance.defaults.headers['Authorization'] = null;
        navigate('/login');
        //window.location.reload(true);
    });
    return <div>Logout</div>;

}