import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import styled from "styled-components";
import {ClockIcon, ClipboardListIcon, PlayIcon, RefreshIcon} from "@heroicons/react/outline";
import {parameters as data} from "../../data";
import axiosInstance from "../../axios";
import ConfirmStartOrReset from "./confirmStartOrReset";

const GridContainer = tw.div`my-8 sm:mx-4 grid grid-cols-1 gap-2`;
const Parameter = tw.div`flex flex-row cursor-pointer bg-palette1-500 shadow-lg rounded-xl p-3 border-palette1-500 border-solid border-2 transition duration-500 hocus:bg-palette1-100`;
const LeftColumn = tw.div`relative flex justify-center items-center w-4/12 max-w-none`;
const RightColumn = tw.div`relative flex-1 flex justify-center items-center`;
const IconContainer = styled.span`
  ${tw`text-white transition duration-300 flex justify-center items-center rounded-full`}
  svg {
    ${tw`w-8 h-8`}
  }
`;
const TextContainer = tw.div`text-white font-bold text-base`;

const SimulationStarted = tw.div`flex flex-row items-center justify-center bg-palette1-200 shadow-lg rounded-xl p-3`;

export default function Status(props) {

    const [start, setStart] = useState(props.parameters.start);
    const [startPopUp, setstartPopUp] = useState(false);

    /*
    useEffect( () => {
            axiosInstance.get("orders/get_start").then((res) => {
                setStart(res.data.start)
            })
        }
        , [setStart]
    );
    */


    const handleClikStart = () => {
        setstartPopUp(true)
    }

    const handlePopStartClose = () => {
        setstartPopUp(false)
    }

    return(
        <>
        <GridContainer>
            { start &&
                <SimulationStarted>
                    <TextContainer>
                        {data.is_started}
                    </TextContainer>
                </SimulationStarted>
            }
            {/* Start / Reset simulation */}
            <Parameter onClick={handleClikStart}>
                <LeftColumn>
                    <IconContainer>
                        { start === false ?
                            <PlayIcon/>
                            :
                            <RefreshIcon/>
                        }
                    </IconContainer>
                </LeftColumn>
                <RightColumn>
                    { start === false ?
                        <TextContainer>
                            {data.initiate}
                        </TextContainer>
                        :
                        <TextContainer>
                            {data.reset}
                        </TextContainer>
                    }
                </RightColumn>
            </Parameter>
        </GridContainer>

        { startPopUp &&
            <ConfirmStartOrReset start={start} handlePopClose={handlePopStartClose}/>
        }
        </>
    )
}