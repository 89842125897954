import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {XIcon} from "@heroicons/react/solid";
import {qrScannerData} from "../../data";


const BodyContainer = tw.div`my-8 bg-palette1-100 p-4 rounded-xl text-center border-solid border-0 border-palette1-500 border-r-4 border-l-4`;
const LogoContainer = tw.div`h-24 w-24 my-10 text-palette1-100 mx-auto flex p-2 justify-center items-center rounded-full bg-palette1-400 shadow-sm`;
const SubTitle = tw.div`text-center my-10 font-light text-xl text-palette1-400`;

const Button = tw.button`w-9/12 px-3 my-10 py-4 rounded-full border-0 text-palette1-100 uppercase font-hairline text-lg bg-palette1-400 shadow-lg transition duration-500 hocus:bg-palette1-100 hocus:text-palette1-400`;

export default function ErrorBadge(props) {


    return(
        <BodyContainer>
            <LogoContainer>
                <XIcon/>
            </LogoContainer>
            <SubTitle>
                {props.qrScannerData.errorBadge}
            </SubTitle>
            <Button type="submit" onClick={props.handlesSubmit}>{qrScannerData.buttonError}</Button>
        </BodyContainer>
    )
}