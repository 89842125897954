import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {useNavigate} from "react-router";
import axiosInstance from "../../axios";
import {rank as data} from "../../data";
import PopupName from "../admin/popUpName";
import PopUpChooseTeam from "./popUpChooseTeam";

const Container = tw.div`flex items-center`;
const LeftContainer = tw.div`flex flex-1 items-center justify-center `;
const ImgContainer = tw.div`text-center w-6/12`;
const ScoreContainer = tw.div`flex-col justify-start text-left w-8/12`;
const Score = tw.div`text-xl font-bold text-black flex`;
const Team = tw.div`text-xl font-bold text-palette1-500 flex items-center`;
const Legend = tw.div`font-normal mr-2`
const UserName = tw.div`text-xl font-bold text-black block`;
const ChooseTeamButton = tw.div`cursor-pointer rounded-xl bg-palette1-500 text-white font-semibold p-1 px-2`
const WaitBeginSimu = tw.div`rounded-xl border-solid border-palette1-500 text-palette1-500 font-semibold p-1 px-2`
const TeamChosen = tw.div`truncate`

export default function Rank(props) {

    function importAll(r) {
        let images = {};
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
    }

    const images = importAll(require.context('../ranks/', false, /\.(png|jpe?g|svg)$/));

    const [score, setScore] = useState(0);
    const [team, setTeam] = useState('')
    const [teams, setTeams] = useState(false)
    const [rank, setRank] = useState(0);
    const [name, setName] = useState('')
    const [start, setStart] = useState(false)

    const [chooseTeamPopUp, setchooseTeamPopUp] = useState(false);

    const handlePopChooseTeamClose = () => {
        setchooseTeamPopUp(false)
    }

    //console.log(team)

    useEffect( ()=> {
            axiosInstance.get("user/get_score").then((res) => {
                //console.log(res.data)
                setScore(res.data.score)
                setName(res.data.name)
                setTeam(res.data.team)
                setTeams(res.data.teams)
                setStart(res.data.start)
            })

        },
        [setScore])



    useEffect( () => {
            if (score > 0 && score < 400) {
                setRank (1);
            }
            else if (score >= 400 && score < 800) {
                setRank (2);
            }
            else if (score >= 800 && score < 1200) {
                setRank (3);
            }
            else if (score >= 1200 && score < 1600) {
                setRank (4);
            }
            else if (score >= 1600 && score < 2000) {
                setRank (5);
            }
            else if (score >= 2000 && score < 2400) {
                setRank (6);
            }
            else if (score >= 2400 && score < 2800) {
                setRank (7);
            }
            else if (score >= 2800 && score < 3200) {
                setRank (8);
            }
            else if (score >= 3200 && score < 3600) {
                setRank (9);
            }
            else if (score >= 3600) {
                setRank (10);
            }
            //console.log(rank)
        },
        [score])


    const handleClickChoose = () => {
        // A faire quand choix d'équipe
        setchooseTeamPopUp(true)
    }

    return (
        <>
            <Container>
                <LeftContainer>
                    <ImgContainer>
                        <img src={images[rank.toString() + ".svg"]} />
                    </ImgContainer>
                </LeftContainer>
                <ScoreContainer>
                    {props.is_main &&
                        <UserName>
                            {name}
                        </UserName>
                    }
                    <Score>
                        <Legend>
                            {data.score}{":"}
                        </Legend>
                        {score.toString()}
                    </Score>
                    <Team>
                        <Legend>
                            {data.team}{":"}
                        </Legend>
                        {teams === true && start === false && team === false &&
                            //Info attendre que la simu commence
                            <WaitBeginSimu>
                                {data.waitBeginSimu}
                            </WaitBeginSimu>

                        }
                        {teams === true && start === true && team === false &&
                            //Afficher bouton de sélection d'équipe
                            <ChooseTeamButton onClick={handleClickChoose}>
                                {data.chooseTeam}
                            </ChooseTeamButton>

                        }
                        {teams === true && start === true && team !== false &&
                            //Afficher l'équipe en question
                                <TeamChosen>
                                    {team}
                                </TeamChosen>
                        }
                    </Team>
                </ScoreContainer>
            </Container>

            {chooseTeamPopUp &&
                <PopUpChooseTeam pop={chooseTeamPopUp} handlePopClose={handlePopChooseTeamClose}/>
            }

        </>
    )
}