import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {useNavigate} from "react-router";
import axiosInstance from "../axios";
import decode from "jwt-decode";
import {Subwrapper} from "../components/templates/Layouts";
import {UserIcon} from "@heroicons/react/solid";
import {registerForm as data} from "../data";


const BodyContainer = tw.div`my-8 bg-palette1-300 p-4 rounded-xl text-center border-solid border-0 border-palette1-500 border-b-4 border-r-4 border-l-4`;
const LogoContainer = tw.div`h-20 w-20 mt-10 text-palette1-100 mx-auto flex p-2 justify-center items-center rounded-full bg-palette1-400 shadow-sm`;

const NameInput = tw.input`mt-10 mb-1 w-9/12 bg-palette1-400 px-4 font-thin text-lg text-black py-3 border-solid border-0 border-b-2 border-l-2 border-palette1-100 rounded-xl leading-tight hocus:border-palette1-500 hocus:outline-none transition duration-300 hocus:shadow-lg`;
const EmailInput = tw.input`mt-10 mb-1 w-9/12 bg-palette1-400 px-4 font-thin text-lg text-black py-3 border-solid border-0 border-b-2 border-l-2 border-palette1-100 rounded-xl leading-tight hocus:border-palette1-500 hocus:outline-none transition duration-300 hocus:shadow-lg`;
const PasswordInput = tw.input`mt-8 mb-1 w-9/12 bg-palette1-400 px-4 font-thin text-lg text-black py-3 border-solid border-0 border-b-2 border-l-2 border-palette1-100 rounded-xl leading-tight hocus:border-palette1-500 hocus:outline-none transition duration-300 hocus:shadow-lg`;

const Connection = tw.button`w-9/12 px-3 mt-10 mb-3 py-4 rounded-full border-0 text-palette1-100 uppercase font-hairline text-lg bg-palette1-400 shadow-lg transition duration-500 hocus:bg-palette1-100 hocus:text-palette1-400`;
const Account = tw.button`w-9/12 px-3 py-4 mt-5 mb-10 rounded-full border-0 text-palette1-400 uppercase font-hairline text-lg bg-palette1-200 shadow-lg`;

const ErrorLabel = tw.p`my-1 text-red-500 text-base italic`;

export default function Register() {

    const navigate = useNavigate();
    const initialFormData = Object.freeze({
        email: '',
        name:'',
        password: '',
        password2: '',
    });

    const [formData, updateFormData] = useState(initialFormData);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [mdperror, setMdperror] = useState(false);
    const [emailerror, setEmailerror] = useState(false);
    const [nameerror, setNameerror] = useState(false);
    const [match, setMatch] = useState(true)

    const validateName = (name) => {
        if (name.includes("Staffer")) {
            return false
        }
        else {
            return true
        }
    }

    const validateEmail = (email) => {
        if(email.includes("@staff.com")){
            return null
        }
        else {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        }
    };

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim(),
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //console.log(formData);
        setMatch(true)
        if ( /^\d+$/.test(formData.password) === false) {
            setMdperror(true);
        }
        else if (formData.password.length !== 4) {
            setMdperror(true);
        }
        else if (validateEmail(formData.email) === null) {
            setEmailerror(true);
        }
        else if (formData.password2 !== formData.password) {
            setMatch(false)
        }
        else if (validateName(formData.name) === false){
            setNameerror(true)
        }
        else if (process.env.REACT_APP_IS_DEMO === "TRUE") {
            window.location.replace('/login')
        }
        else {

            axiosInstance
                .post(`user/register/`, {
                    email: formData.email,
                    password: formData.password,
                    name: formData.name,
                })
                .then((res) => {
                    navigate('/login');
                    //console.log(res);
                    //console.log(res.data);
                }).catch((error) => {
                setError(true)
            });
        }
    };

    const resetMailError = () => {
        setError(false);
        setEmailerror(false);
    };

    const resetNameError = () => {
        setError(false);
        setNameerror(false);
    };

    const resetMdpError = () => {
        setError(false);
        setMatch(true);
        setMdperror(false);
    }

    const resetMatch = () => {
        setMatch(true);
        setMdperror(false);
    }

    return(
        <Subwrapper>
            <BodyContainer>
                <LogoContainer>
                    <UserIcon css={tw`pb-4`}/>
                </LogoContainer>
                <form>
                    <NameInput
                        css={nameerror ? tw`transition duration-1000 border-2 border-red-500 shadow-lg` : tw`` }
                        type="text"
                        id="name"
                        placeholder={data.name}
                        name="name"
                        autoFocus
                        required
                        onChange={handleChange}
                        onClick={resetNameError}
                    />
                    <ErrorLabel css={nameerror ? tw`block` : tw`hidden`}>
                        {data.nameError}
                    </ErrorLabel>
                    <EmailInput
                        css={emailerror ? tw`transition duration-1000 border-2 border-red-500 shadow-lg` : tw`` }
                        type="text"
                        id="email"
                        placeholder={data.email}
                        name="email"
                        required
                        onChange={handleChange}
                        onClick={resetMailError}
                        autoComplete="current-email"
                    />
                    <ErrorLabel css={emailerror ? tw`block` : tw`hidden`}>
                        {data.emailError}
                    </ErrorLabel>
                    <PasswordInput
                        css={mdperror ? tw`border-2 border-red-500 shadow-lg` : tw`` }
                        required
                        name="password"
                        placeholder={data.password}
                        type="password"
                        id="password"
                        inputProps={{ inputMode: 'numeric' }}
                        onChange={handleChange}
                        onClick={resetMdpError}
                    />
                    <ErrorLabel css={mdperror ? tw`block` : tw`hidden`}>
                        {data.passwordError}
                    </ErrorLabel>
                    <PasswordInput
                        css={!match ? tw`border-2 border-red-500 shadow-lg` : tw`` }
                        required
                        name="password2"
                        placeholder={data.passwordConfirmation}
                        type="password"
                        id="password2"
                        inputProps={{ inputMode: 'numeric' }}
                        onChange={handleChange}
                        onClick={resetMdpError}
                    />
                    <ErrorLabel css={!match ? tw`block` : tw`hidden`}>
                        {data.matchError}
                    </ErrorLabel>
                    <Connection type="submit" onClick={handleSubmit}>{data.submit}</Connection>
                    <ErrorLabel css={error ? tw`block not-italic font-medium` : tw`hidden`}>
                        {data.error}
                    </ErrorLabel>
                    <Account type={"submit"} onClick={()=> navigate('/login')}>{data.account}</Account>
                </form>
            </BodyContainer>
        </Subwrapper>
    )
}