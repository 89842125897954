import React, {useRef, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {confirmStartOrReset as data} from "../../data";
import axiosInstance from "../../axios";
import {useOutsideClose} from "../templates/messagePopUp";
import { CSVLink } from "react-csv";


const Wrapper = tw.div`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none`;
const Container = tw.div`relative my-6 flex-grow mx-auto max-w-screen-sm`;
const Content = tw.div`mx-2 border-0 p-3 px-6 rounded-xl shadow-2xl relative flex flex-col bg-palette1-200 outline-none`;

const Header = tw.div`flex items-center justify-center`;
const HeaderTitle = tw.div`pl-1 text-xl sm:text-2xl font-semibold text-black text-center`;

const Body = tw.div`mb-8 flex flex-col justify-center items-center`;
const TextArea = tw.div`flex-grow my-2 mb-1 px-4 font-thin text-center text-base text-black py-3`;
const ButtonCSV = tw.div`uppercase cursor-pointer shadow-lg rounded-xl p-3 text-palette1-100 bg-palette1-400 border-solid border-palette1-400`;

const ErrorLabel = tw.p`text-center mt-0 mb-4 text-red-500 text-base font-medium`;

const Footer =tw.div`flex flex-row justify-center mb-2`;
const TextButton = tw.div`uppercase p-2 rounded-xl bg-palette1-400 text-black font-semibold text-base cursor-pointer border-2 border-solid border-palette1-400 transition duration-500 hocus:border-palette1-500 hocus:shadow-lg`;




export default function ConfirmStartOrReset(props) {

    //Show error message if bad request
    const [error, setError] = useState(false)

    const [csvData, setCsvData] = useState([])
    const csvLink = useRef()


    const handleResetError = () => {
        setError(false);
    }

    const handleStartReset = () => {
        if (props.start === false) {
            axiosInstance.post('/parameters/set_start').then((res) => {
                window.location.reload();
            }).catch((error) => {
                setError(true);
            })
        }
        else {
            axiosInstance.post('/parameters/reset').then((res) => {
                window.location.reload();
            }).catch((error) => {
                setError(true);
            })
        }
    }

    const handleExportCSV = () => {
        axiosInstance.post('/parameters/save').then((res) => {
            setCsvData(res.data)
            csvLink.current.link.click()
        }).catch((error) => {
            setError(true);
        })
    }



    const wrapperRef = useRef(null);
    useOutsideClose(wrapperRef, props.handlePopClose);

    return (
        <Wrapper>
            <Container>
                <Content ref={wrapperRef}>
                    <Header>
                        <HeaderTitle>
                            {props.start ? data.titleReset : data.titleStart}
                        </HeaderTitle>
                    </Header>
                    <Body>
                        { props.start &&
                            <>
                                <TextArea>
                                    {data.saveToCsv}
                                </TextArea>
                                <ButtonCSV onClick={handleExportCSV}>
                                    {data.save}
                                </ButtonCSV>
                                <CSVLink
                                    data={csvData}
                                    filename='historique_commandes.csv'
                                    className='hidden'
                                    ref={csvLink}
                                    target='_blank'
                                />
                                <TextArea>
                                    {data.infoSaveToCsv}
                                </TextArea>
                            </>
                        }
                    </Body>
                    <ErrorLabel css={error ? tw`block` : tw`hidden`}>
                        {data.error}
                    </ErrorLabel>
                    <Footer>
                        <TextButton onClick={props.handlePopClose} css={tw`mr-3`}>
                            {data.cancel}
                        </TextButton>
                        <TextButton onClick={handleStartReset} css={tw`ml-3`}>
                            {data.validate}
                        </TextButton>
                    </Footer>
                </Content>
            </Container>
        </Wrapper>
    )
}
