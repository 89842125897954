import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import styled from "styled-components";
import {motion} from "framer-motion";
import {PlusIcon, TrashIcon} from "@heroicons/react/solid";
import {Staffers as data} from "../../data";
import axiosInstance from "../../axios";
import PopUpStaffer from "./popUpStafferDetail";

const List = tw.ol`relative pl-0 my-8`;
const ListItem = tw.li`sm:w-10/12 sm:mx-auto cursor-pointer list-none my-4 px-4 py-4 rounded-2xl shadow-md flex flex-row items-center bg-palette1-400 border-solid border-palette1-400 transition duration-300 hover:border-palette1-100`;

const AddStaffItem = tw.li`sm:w-10/12 sm:mx-auto border-transparent cursor-pointer list-none my-4 px-4 py-4 rounded-2xl shadow-md flex flex-row items-center bg-palette1-100 border-solid border-palette1-100 transition duration-300 hover:border-palette1-500`;
const LeftColumn = tw.div`relative flex-1 items-center max-w-none text-center font-bold text-base`;
const RightColumn = tw.div`relative w-3/12 flex justify-center items-center`;

const ButtonContainer = tw.div`p-1 rounded-full flex items-center justify-center h-8 w-8 text-palette1-400`;


function entierAleatoire(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function Staffers(props) {

    const [staffers, setStaffers] = useState(null)
    const [staffersNb, setStaffersNb] = useState(0)
    const [open, setOpen] = useState(false)
    const [focusedStaffer, setFocusedStaffer] = useState(null)

    useEffect( ()=>{
        axiosInstance.get('user/scanners', {}).then((res)=>{
            const temp = res.data
            //console.log(temp)
            setStaffers(temp)
        }).catch((error)=>{
            //Handle Errors
            //console.log("Error")
        })
    }, [setStaffers])

    useEffect( ()=>{
        if (staffers !== null && staffers.length !== 0) {
            setStaffersNb(Math.max(...staffers.map(staff => parseInt(staff.email[8])) ))
        }
        else if (staffers !== null && staffers.length === 0) {
            setStaffersNb(staffers.length)
        }
    },[staffers])


    const handleAddStaffer = () => {
        axiosInstance.post('user/register_scanner/', {email:"staffer_"+ (staffersNb+1).toString() + "@staff.com", password: entierAleatoire(100000,999999), name:"Staffer_"+(staffersNb+1).toString()})
            .then((res)=>{
                window.location.reload()
            })
            .catch((error)=>{
            //Handle Errors

            })
    }

    const handleDeleteStaffer = (event,staffer) => {

        event.cancelBubble = true;
        if(event.stopPropagation) event.stopPropagation();

        axiosInstance.delete('user/scanner/'+ staffer.id.toString() +'/delete').then((res)=>{
            window.location.reload();
        }).catch((error)=>{
                //Handle Errors
            })
    }

    const handlePopClose = () => {
        setOpen(false)
        setFocusedStaffer(null)
    }

    const handleClick = (staffer) => {
        setFocusedStaffer(staffer)
        setOpen(true)
    }

    return(
        <>
            <List>
                {staffers !== null &&
                    staffers.map((staffer)=>{
                        return(
                            <ListItem key={staffer.id} onClick={() => {handleClick(staffer)}}>
                                <LeftColumn>
                                    {staffer.email}
                                </LeftColumn>
                                <RightColumn>
                                    <ButtonContainer onClick={(event) => {handleDeleteStaffer(event,staffer)}} css={tw`text-palette1-100 border-solid border-palette1-100 transition duration-500 hover:text-palette1-500`}>
                                        <TrashIcon/>
                                    </ButtonContainer>
                                </RightColumn>
                            </ListItem>
                        )})
                }


                <AddStaffItem onClick={handleAddStaffer}>
                    <LeftColumn>
                        {data.addStaffer}
                    </LeftColumn>
                    <RightColumn>
                        <ButtonContainer>
                            <PlusIcon/>
                        </ButtonContainer>
                    </RightColumn>
                </AddStaffItem>
            </List>
            {open &&
                <PopUpStaffer pop={open} staffer={focusedStaffer} handlePopClose={handlePopClose}/>
            }
        </>
    )
}