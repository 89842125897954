import React, {useEffect, useRef, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import styled from "styled-components";
import {ClockIcon, ClipboardListIcon, ClipboardCheckIcon, DatabaseIcon, TagIcon, UserGroupIcon, UserAddIcon, TrendingUpIcon} from "@heroicons/react/outline";
import {parameters as data} from "../../data";
import axiosInstance from "../../axios";
import PopupDuration from "./popUpDuration";
import PopupOrders from "./popUpMaxOrders";
import PopupBadgesValidation from "./popUpBadgesValidation";
import PopupName from "./popUpName";
import {CSVLink} from "react-csv";
import PopupTeamsValidation from "./popUpTeamsValidation";
import PopupMaxTeamEffValidation from "./popUpMaxTeamEff";
import PopupPercentageValidation from "./popUpPercentageValidation";

const GridContainer = tw.div`my-8 sm:mx-4 grid grid-cols-2 gap-4`;
const Parameter = tw.div`flex flex-row cursor-pointer bg-palette1-500 shadow-lg rounded-xl p-3 border-palette1-500 border-solid border-2 transition duration-500 hocus:bg-palette1-100`;
const LeftColumn = tw.div`relative flex justify-center items-center w-4/12 max-w-none`;
const RightColumn = tw.div`relative flex-1 flex justify-center items-center`;
const IconContainer = styled.span`
  ${tw`text-white transition duration-300 flex justify-center items-center rounded-full`}
  svg {
    ${tw`w-8 h-8`}
  }
`;
const TextContainer = tw.div`text-white font-bold text-base`;



export default function Parameters(props) {

    const [duration, setDuration] = useState(props.parameters.delete_time);
    const [orders, setOrders] = useState(props.parameters.max_orders);
    const [badges, setBadges] = useState(props.parameters.badges_validation);
    const [name, setName] = useState(props.parameters.name);
    const [teams, setTeams] = useState(props.parameters.teams);
    const [maxTeamEff, setMaxTeamEff] = useState(props.parameters.max_team_eff);
    const [varPercentagePrecedent,setVarPercentagePrecedent] = useState(props.parameters.var_percentage_precedent);


    const [durationPopUp, setdurationPopUp] = useState(false);
    const [ordersPopUp, setordersPopUp] = useState(false);
    const [badgesPopUp, setbadgesPopUp] = useState(false);
    const [namePopUp, setnamePopUp] = useState(false);
    const [teamsPopUp, setteamsPopUp] = useState(false);
    const [maxTeamEffPopUp, setmaxTeamEffPopUp] = useState(false);
    const [varPencentagePopUp, setvarPencentagePopUp] = useState(false);

    const [csvData, setCsvData] = useState([])
    const csvLink = useRef()

    const handleExportCSV = () => {
        axiosInstance.post('/parameters/save').then((res) => {
            setCsvData(res.data)
            csvLink.current.link.click()
        }).catch((error) => {
            //Catch error
        })
    }

    const handleClickDuration = () => {
        setdurationPopUp(true)
    }

    const handleClickOrders = () => {
        setordersPopUp(true)
    }

    const handleClickBadges = () => {
        setbadgesPopUp(true)
    }

    const handleClickName = () => {
        setnamePopUp(true)
    }

    const handleClickTeams = () => {
        setteamsPopUp(true)
    }

    const handleClickMaxTeamEff = () => {
        setmaxTeamEffPopUp(true)
    }

    const handleClickVarPercentage = () => {
        setvarPencentagePopUp(true)
    }




    const handlePopDurationClose = () => {
        setdurationPopUp(false)
    }

    const handlePopOrdersClose = () => {
        setordersPopUp(false)
    }

    const handlePopBadgesClose = () => {
        setbadgesPopUp(false)
    }

    const handlePopNameClose = () => {
        setnamePopUp(false)
    }

    const handlePopTeamsClose = () => {
        setteamsPopUp(false)
    }

    const handlePopMaxTeamEffClose = () => {
        setmaxTeamEffPopUp(false)
    }

    const handlePopVarPrecedentClose = () => {
        setvarPencentagePopUp(false)
    }


    return(
        <>
        <GridContainer>

            {/* Nom de le structure */}
            <Parameter onClick={handleClickName}>
                <LeftColumn>
                    <IconContainer>
                        <TagIcon/>
                    </IconContainer>
                </LeftColumn>
                <RightColumn>
                    <TextContainer>
                        {name}
                    </TextContainer>
                </RightColumn>
            </Parameter>

            {/* Durée de vie d'une commande */}
            <Parameter onClick={handleClickDuration}>
                <LeftColumn>
                    <IconContainer>
                        <ClockIcon/>
                    </IconContainer>
                </LeftColumn>
                <RightColumn>
                    <TextContainer>
                        {duration} {data.duration}
                    </TextContainer>
                </RightColumn>
            </Parameter>


            {/* Nombre maximum de commandes */}
            <Parameter onClick={handleClickOrders}>
                <LeftColumn>
                    <IconContainer>
                        <ClipboardListIcon/>
                    </IconContainer>
                </LeftColumn>
                <RightColumn>
                    <TextContainer>
                        {orders} {data.orders}
                    </TextContainer>
                </RightColumn>
            </Parameter>

            {/* Equipes ou pas */}
            <Parameter onClick={handleClickTeams}>
                <LeftColumn>
                    <IconContainer>
                        <UserGroupIcon/>
                    </IconContainer>
                </LeftColumn>
                <RightColumn>
                    <TextContainer>
                        {teams === true ? data.teamsTrue : data.teamsFalse}
                    </TextContainer>
                </RightColumn>
            </Parameter>

            {/* Nombre max de personnes par équipe */}
            <Parameter onClick={handleClickMaxTeamEff}>
                <LeftColumn>
                    <IconContainer>
                        <UserAddIcon/>
                    </IconContainer>
                </LeftColumn>
                <RightColumn>
                    <TextContainer>
                        {maxTeamEff} {data.maxTeamEff}
                    </TextContainer>
                </RightColumn>
            </Parameter>

            {/* Lots à récupérer pour les badges ou pas */}
            <Parameter onClick={handleClickBadges}>
                <LeftColumn>
                    <IconContainer>
                        <ClipboardCheckIcon/>
                    </IconContainer>
                </LeftColumn>
                <RightColumn>
                    <TextContainer>
                        {badges === true ? data.badgesTrue : data.badgesFalse}
                    </TextContainer>
                </RightColumn>
            </Parameter>

            {/* Comment calculer les pourcentages affichés */}
            <Parameter onClick={handleClickVarPercentage}>
                <LeftColumn>
                    <IconContainer>
                        <TrendingUpIcon/>
                    </IconContainer>
                </LeftColumn>
                <RightColumn>
                    <TextContainer>
                        {data.percentage}
                    </TextContainer>
                </RightColumn>
            </Parameter>

            {/* Sauvegarder commandes en CSV */}
            <Parameter onClick={handleExportCSV}>
                <LeftColumn>
                    <IconContainer>
                        <DatabaseIcon/>
                    </IconContainer>
                </LeftColumn>
                <RightColumn>
                    <TextContainer>
                        {data.CSV}
                    </TextContainer>
                </RightColumn>
            </Parameter>
            <CSVLink
                data={csvData}
                filename='historique_commandes.csv'
                css={tw`hidden`}
                ref={csvLink}
                target='_blank'
            />

        </GridContainer>

            {durationPopUp &&
                <PopupDuration duration={duration} pop={durationPopUp} handlePopClose={handlePopDurationClose}/>
            }
            {ordersPopUp &&
                <PopupOrders orders={orders} pop={ordersPopUp} handlePopClose={handlePopOrdersClose}/>
            }
            {teamsPopUp &&
                <PopupTeamsValidation teams={teams} pop={teamsPopUp} handlePopClose={handlePopTeamsClose}/>
            }
            {maxTeamEffPopUp &&
                <PopupMaxTeamEffValidation maxTeamEff={maxTeamEff} pop={maxTeamEffPopUp} handlePopClose={handlePopMaxTeamEffClose}/>
            }
            {badgesPopUp &&
                <PopupBadgesValidation badges={badges} pop={badgesPopUp} handlePopClose={handlePopBadgesClose}/>
            }
            {varPencentagePopUp &&
                <PopupPercentageValidation varPercentagePrecedent={varPercentagePrecedent} pop={varPencentagePopUp} handlePopClose={handlePopVarPrecedentClose}/>
            }
            {namePopUp &&
                <PopupName name={name} pop={namePopUp} handlePopClose={handlePopNameClose}/>
            }

        </>
    )
}