import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import { header as data } from "../data.js";
import styled from "@emotion/styled";
import {checkAuth} from "../checkUser";
import {ArrowLeftIcon} from "@heroicons/react/solid";
import {useNavigate} from "react-router";

const Container = tw.div`
    flex justify-between items-center
    max-w-screen-lg mx-auto
    lg:pt-4 lg:px-4 xl:px-0
    bg-palette1-400
`;

export const NavLinks = tw.div`inline-block mr-4`;

export const NavLink = tw.a`
  text-sm lg:text-sm lg:mx-6 lg:my-0 text-black p-3 px-4
  font-normal tracking-wide border-transparent no-underline rounded-full text-palette1-100 bg-palette1-300 
  border-solid border-palette1-300
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex ml-4 text-palette1-100 items-center font-bold`};
  
  img {
    ${tw`w-10 mr-3`}
  }
  
  svg {
    ${tw`w-6 h-6 text-palette1-100`}
  }
`;

export const DesktopNavLinks = tw.nav`
  flex flex-1 justify-between items-center py-4
`;

export default function Header() {


    const navigate = useNavigate();

    const Links = [
        <NavLinks key={1}>
            {!checkAuth() && <NavLink href="/login">{data.login}</NavLink>}
            {checkAuth() && <NavLink href="/logout">{data.logout}</NavLink>}
        </NavLinks>
    ];

    const DemoLinks = [
        <NavLinks key={1}>
            {<NavLink href={"https://www.thedrinkmarket.fr"}>{data.landing}</NavLink>}
        </NavLinks>
    ];


    const Logo =
        <>
            {(window.location.pathname === '/')  && <LogoLink> TheDrinkMarket </LogoLink>}
            {window.location.pathname !== '/' && <LogoLink onClick={()=>{navigate(-1)}} css={tw`cursor-pointer`}><ArrowLeftIcon/></LogoLink>}
        </>;

    return (
        <Container css={window.location.pathname.includes("/display") ? tw`hidden` : tw``}>
            <DesktopNavLinks>
                {Logo}
                {process.env.REACT_APP_IS_DEMO === "TRUE"? DemoLinks : Links}
            </DesktopNavLinks>
        </Container>
    )
}