import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {HeartIcon as FilledHeart} from "@heroicons/react/solid";
import {HeartIcon as OutlinedHeart} from "@heroicons/react/outline";
import {TrendingUpIcon} from "@heroicons/react/solid";
import {TrendingDownIcon} from "@heroicons/react/solid";
import {drinkDetail as data} from "../../data";
import axiosInstance from "../../axios";
import {useParams} from "react-router";

const InfoContainer = tw.div`p-4 rounded-xl border-solid border-0`;
const CardHeader= tw.div`flex flex-row`;
const LeftColumn = tw.div`relative flex items-center w-9/12 text-center max-w-none text-left`;
const RightColumn = tw.div`relative flex-1 flex justify-end items-center`;
const Name = tw.div`font-bold text-palette1-400 text-2xl pl-2`;
const LogoContainer = tw.div`h-10 w-10 flex p-2 justify-center items-center rounded-full hocus:bg-gray-500`;
const Price = tw.div`font-medium text-4xl text-center mt-4`;
const Modifications = tw.div`flex flex-row justify-center`;
const ArrowContainer = tw.div`h-8 w-8 flex p-2 self-center justify-center items-center`;
const TextContainer = tw.div`font-normal self-center text-center text-palette1-400 text-lg`;
const Krash = tw.div`font-medium uppercase text-4xl text-black text-center my-2`;

export default function DrinkDetailHeader(props) {

    const beer = props.beer
    const [isPinned, setIsPinned] = useState(props.pinned)
    let {id} = useParams();

    const [varPercentagePrecedent,setVarPercentagePrecedent] = useState(null);
    useEffect( ()=>{
            axiosInstance.get('/parameters/get_var_percentage_precedent').then((res)=>{
                const temp = res.data;
                setVarPercentagePrecedent(temp.var_percentage_precedent);
            }).catch((error)=>{
                // Handle error here
            })
        },
        [setVarPercentagePrecedent]);

    useEffect(() => { setIsPinned(props.pinned)},[props.pinned])

    function Percentage(props) {

        //console.log("percentage", props.beer.price_hist);

        if (props.beer.price_hist === []) return <></>
        else {
            if(props.beer.price_hist.length === 1) {return <TextContainer css={tw`text-palette1-400`} >(- 0%)</TextContainer>}
            else {
                // Give shift percentage
                const variation = ((Math.abs(props.beer.price_hist[props.beer.price_hist.length - 2]-props.beer.price_hist[props.beer.price_hist.length - 1])/props.beer.price_hist[props.beer.price_hist.length - 2])*100).toFixed(2)

                if (props.beer.is_going_down) {
                    return <TextContainer css={tw`text-palette1-400`} >(- {variation}%)</TextContainer>
                }
                else{
                    return <TextContainer css={tw`text-palette1-400`} >(+ {variation}%)</TextContainer>
                }
            }
        }
    }
    //console.log(isPinned)

    //OnClick Heart
    function handlePin() {
        //console.log("click")
        //console.log(isPinned)
        //Pin la boisson dans axios + vérifier si besoin de reload ou pas

        if (isPinned) {
            axiosInstance.put("/beers/" + id.toString() + "/remove", {
                id:id,
            }).then((res)=>{
                //window.location.reload();
                setIsPinned(false)
            })
        }
        else {
            axiosInstance.put("/beers/" + id.toString() + "/add", {
                id:id,
            }).then((res)=>{
                //window.location.reload();
                setIsPinned(true)
            })
        }
    }


    return(
        <InfoContainer css={beer.is_crash === false ? (beer.n <= 0 ? tw`bg-gray-300 transition duration-500`  : (beer.is_going_down === true ? tw`bg-green-700 transition duration-500` : tw`bg-red-700 transition duration-500`)) : tw`bg-palette1-200 transition duration-500`}>
            <CardHeader>
                <LeftColumn
                    css={beer.n <= 0 ? tw`py-12 w-full justify-center` : tw`text-palette1-400`}
                >
                    <Name
                        css={beer.is_crash === false ? (beer.n <= 0 ? tw`text-black pl-0` : tw``) : tw`text-black`}
                    >
                        {beer.name}
                    </Name>
                </LeftColumn>
                {beer.n > 0 &&
                    <RightColumn>
                        {
                            //{checkAuth() &&
                            <LogoContainer onClick={() => {
                                handlePin()
                            }}>
                                {isPinned &&
                                    <FilledHeart
                                        css={beer.is_crash === false ? (beer.is_going_down === true ? tw`text-palette1-400` : tw`text-palette1-400`) : tw`text-black`}/>
                                }
                                {!isPinned &&
                                    <OutlinedHeart
                                        css={beer.is_crash === false ? (beer.is_going_down === true ? tw`text-palette1-400` : tw`text-palette1-400`) : tw`text-black`}/>
                                }
                            </LogoContainer>
                        }
                    </RightColumn>
                }
            </CardHeader>
            {beer.n > 0 &&
                <>
                    <Price
                        css={beer.is_crash === false ? (beer.is_going_down === true ? tw`text-palette1-400` : tw`text-palette1-400`) : tw`text-black`}
                    >
                        {beer.price_hist[beer.price_hist.length - 1]} {data.currency}
                    </Price>
                    {beer.is_crash === false &&
                        <Modifications>
                            <ArrowContainer>
                                {beer.is_going_down &&
                                    <TrendingDownIcon css={tw`text-palette1-400`}/>
                                }
                                {!beer.is_going_down &&
                                    <TrendingUpIcon css={tw`text-palette1-400`}/>
                                }
                            </ArrowContainer>
                            {varPercentagePrecedent === true &&
                                <Percentage beer={beer}/>
                            }
                            {varPercentagePrecedent === false &&
                                <TextContainer>
                                    {"("}{(((beer.price_hist[beer.price_hist.length - 1]-beer.average_price)/beer.average_price)*100).toFixed(2)}{"%)"}
                                </TextContainer>
                            }
                        </Modifications>
                    }
                    {beer.is_crash === true &&
                        <Krash>
                            {data.krash}
                        </Krash>
                    }
                </>
            }
        </InfoContainer>
    )
}

