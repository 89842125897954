import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {drinkDetail as data} from "../../data";
import axiosInstance from "../../axios";
import {useNavigate, useParams} from "react-router";
import MessagePopUp from "../templates/messagePopUp";
import MessageErrorPopUp from "../templates/errorPopUp";


const ButtonContainer = tw.div`mt-8 mb-4 text-center`;
const Button = tw.div`mx-8 cursor-pointer rounded-full shadow-lg px-2 py-4 bg-palette1-400`;
const Text = tw.div`uppercase font-normal text-xl text-palette1-100`;

export default function OrderButton(props) {

    let {id} = useParams();
    const navigate = useNavigate();
    const [pop, setPop] = useState(false);
    const [error, setError] = useState(false);
    const [errorStatus, setErrorStatus] = useState("")

    const handleClose = () => {
        setPop(false);
        setError(false);
    }

    const handleClick = () => {
        axiosInstance.post('/orders/', {beer: id}).then((res)=>{
            navigate('/');

        }).catch((error) => {
            setError(true)
            if (error.response.data.error ==="max_orders"){
                setErrorStatus("max_orders")
            }
            else if (error.response.data.error ==="empty_stock"){
                setErrorStatus("empty_stock")
            }
            setPop(true)
        })
    }

    return(
        <>
            <ButtonContainer>
                {/*<Button onClick={ ()=>{setPop(true)} }>*/}
                <Button onClick={ handleClick }>
                    <Text>
                        {data.orderButton}
                    </Text>
                </Button>
            </ButtonContainer>
            {/*
            {pop &&
                <MessagePopUp
                    handleConfirm={handleClick}
                    confirmTxt={data.popUp.confirmTxt}
                    title={data.popUp.title}
                    body={data.popUp.body}
                    handleClose={handleClose}
                    closeTxt={data.popUp.closeTxt}
                    error={error}
                    errorTxt={errorStatus === "max_orders" ? data.popUp.errorMaxOrders : (errorStatus === "empty_stock" ? data.popUp.errorEmptyStock : data.popUp.errorTxt)}
                />
            }
            */}
            {pop &&
                <MessageErrorPopUp
                    handleClose = {handleClose}
                    okTxt = {data.popUp.okTxt}
                    error = {error}
                    errorTxt = {errorStatus === "max_orders" ? data.popUp.errorMaxOrders : (errorStatus === "empty_stock" ? data.popUp.errorEmptyStock : data.popUp.errorTxt)}
                    />
            }
        </>
    )
}