import axios from 'axios';

//const baseURL = "https://" + window.location.hostname+ "/api/"
//const baseURL = 'https://localhost/api/';
//const baseURL = 'http://localhost:4455/api/';

let baseURL = ''

if (window.location.protocol === "http:" && window.location.port === "3000") {
    baseURL = 'http://' + window.location.hostname + ':4455/api/';
} else if (window.location.protocol === "http:" && window.location.hostname.includes("192.168.")) {
    baseURL = "http://" + window.location.hostname+ "/api/"
} else {
    baseURL = "https://" + window.location.hostname+ "/api/"
}

/*
console.log(
    window.location.protocol,
    window.location.hostname,
    window.location.port
)
*/

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 5000,
    headers: {
        Authorization: localStorage.getItem('access_token')
            ? 'JWT ' + localStorage.getItem('access_token')
            : null,
        'Content-Type': 'application/json',
        accept: 'application/json',
    },
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        //console.log(error.response.data.detail)
        //console.log(error.response.data.code);
        //console.log(error.response.status);
        //console.log(error.response.statusText);
        //console.log(originalRequest.url)

        if (typeof error.response === 'undefined') {
            alert(
                'A server/network error occurred. ' +
                'Looks like CORS might be the problem. ' +
                'Sorry about this - we will get it fixed shortly.'
            );
            return Promise.reject(error);
        }

        if (
            error.response.status === 401 &&
            originalRequest.url === baseURL + 'token/refresh/'
        ) {
            window.location.href = '/login/';
            return Promise.reject(error);
        }

        if (
            // On commente certaines condition car Docker perd les infos dans la bataille
            // Pas grave car la seule 401 que l'on a c'est lorsque l'on est pas connecté
            //error.response.data.code === 'token_not_valid' &&
            error.response.status === 401 &&
            error.response.data.detail !== "No active account found with the given credentials"
            //error.response.statusText === 'Unauthorized'
        ) {
            const refreshToken = localStorage.getItem('refresh_token');

            if (refreshToken) {
                const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

                // exp date in token is expressed in seconds, while now() returns milliseconds:
                const now = Math.ceil(Date.now() / 1000);
                //console.log(tokenParts.exp);
                //console.log(now);

                if (tokenParts.exp > now) {
                    return axiosInstance
                        .post('/token/refresh/', { refresh: refreshToken })
                        .then((response) => {
                            localStorage.setItem('access_token', response.data.access);
                            //console.log("test", response.data);
                            // Uncomment only if Django settings new refresh is on True
                            //localStorage.setItem('refresh_token', response.data.refresh);

                            axiosInstance.defaults.headers['Authorization'] =
                                'JWT ' + response.data.access;
                            originalRequest.headers['Authorization'] =
                                'JWT ' + response.data.access;

                            return axiosInstance(originalRequest);
                        })
                        .catch((err) => {
                            //console.log(err);
                        });
                } else {
                    console.log('Refresh token is expired', tokenParts.exp, now);
                    window.location.href = '/login/';
                }
            } else {
                console.log('Refresh token not available.');

                window.location.href = '/login/';
            }
        }

        // specific error handling done elsewhere
        return Promise.reject(error);
    }
);

export default axiosInstance;
