import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {HomeIcon} from "@heroicons/react/outline";
import {useNavigate} from "react-router";

const Container = tw.div`h-8 w-8 p-3 text-palette1-400 fixed bg-palette1-500 rounded-full shadow-2xl`;

export default function AdminButton() {

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/admin')
    }

    return(
        <Container onClick={handleClick} style={{bottom:15, right:15}}>
            <HomeIcon/>
        </Container>
    )
}