import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import styled from "styled-components";
import axiosInstance from "../axios";
import {TrendingUpIcon} from "@heroicons/react/solid";
import {TrendingDownIcon} from "@heroicons/react/solid";
import {LightningBoltIcon} from "@heroicons/react/solid";
import {CurrencyDollarIcon} from "@heroicons/react/solid";
import {ShoppingCartIcon} from "@heroicons/react/solid";

import {winners as data} from "../data";
import {Title} from "../components/templates/Titles";
import {RefreshIcon} from "@heroicons/react/outline";


const Container = tw.div`px-4 sm:px-6 md:px-8 xl:px-0 mt-4`;
const WinnersContainer = tw.div`flex flex-wrap items-stretch justify-center mt-10`;
const TwoCol = tw.div`flex flex-col items-center justify-center text-palette1-200`;
const LeftColumn = tw.div`relative flex items-center justify-center w-2/12 text-center`;
const RightColumn = tw.div`flex items-center justify-center relative flex-1 text-center`;
const Label = tw.div`mt-2 text-base font-normal`;
const UserName = tw.div`my-6 text-center text-black text-2xl font-semibold`;
const Score = tw.div`mb-2 text-center text-palette1-500 text-xl font-normal`;

const Reset = tw.div`mt-4 flex flex-row cursor-pointer bg-palette1-500 shadow-lg rounded-xl p-3 border-palette1-500 border-solid border-2 transition duration-500 hocus:bg-palette1-100`;
const ResetLeftColumn = tw.div`relative flex justify-center items-center w-4/12 max-w-none`;
const ResetRightColumn = tw.div`relative flex-1 flex justify-center items-center`;
const IconContainer = styled.span`
  ${tw`text-white transition duration-300 flex justify-center items-center rounded-full`}
  svg {
    ${tw`w-8 h-8`}
  }
`;
const TextContainer = tw.div`text-white font-bold text-base`;


const Winner = tw.div`flex-grow sm:w-1/4 m-4 bg-white rounded-xl shadow-lg p-4`

const Error = tw.div`mt-48 text-center text-palette1-500 text-2xl font-semibold`

function WhichIcon(props) {
    if (props.index === 0) {
        return(
            <CurrencyDollarIcon/>
        )
    }
    else if (props.index === 1) {
        return(
            <LightningBoltIcon/>
        )
    }
    else if (props.index === 2) {
        return(
            <ShoppingCartIcon/>
        )
    }
    else if (props.index === 3) {
        return(
            <TrendingDownIcon/>
        )
    }
    else if (props.index === 4){
        return(
            <TrendingUpIcon/>
        )
    }
}

export default function DisplayWinners() {

    const [winners, setWinners] = useState(null)
    const [error, setError] = useState(false)

    useEffect( ()=>{
        axiosInstance.get('user/get_best_participants_detail').then((res)=>{
            setError(false)
            const temp = res.data;
            //console.log(temp);
            setWinners(temp);
        }).catch((error)=>{
        setError(true)
        })
    }, [setWinners])

    const handleRefresh = () => {
        axiosInstance.get('user/get_best_participants_detail').then((res)=>{
            setError(false)
            const temp = res.data;
            //console.log(temp);
            setWinners(temp);
        }).catch((error)=>{
            setError(true)
        })
    }

    return(
        <Container>
            <Title css={tw`text-center`}>
                {data.title}
            </Title>
            {error &&
                <Error>
                    {data.error}
                </Error>
            }

            <Reset onClick={handleRefresh}>
                <ResetLeftColumn>
                    <IconContainer>
                        <RefreshIcon/>
                    </IconContainer>
                </ResetLeftColumn>
                <ResetRightColumn>
                    <TextContainer>
                        {data.reset}
                    </TextContainer>
                </ResetRightColumn>
            </Reset>

            <WinnersContainer>
                {winners!== null && winners.map((winner,index)=>{
                    return(
                        <Winner key={index}>
                            <TwoCol>
                                <LeftColumn>
                                    <WhichIcon index={index}/>
                                </LeftColumn>
                                <RightColumn>
                                    <Label>
                                        {data.label[index]}
                                    </Label>
                                </RightColumn>
                            </TwoCol>
                            <UserName>
                                {winner.name}
                            </UserName>
                            <Score>
                                {data.score} {" "} {winner.score}
                            </Score>
                        </Winner>
                    )

                })}

            </WinnersContainer>
        </Container>
    )
}