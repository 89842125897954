import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import styled from "styled-components";
import axiosInstance from "../axios";
import {displayWinners as displayData, winners as data} from "../data";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {ArrowsExpandIcon} from "@heroicons/react/solid";
import {TrendingUpIcon} from "@heroicons/react/solid";
import {TrendingDownIcon} from "@heroicons/react/solid";
import {LightningBoltIcon} from "@heroicons/react/solid";
import {CurrencyDollarIcon} from "@heroicons/react/solid";
import {ShoppingCartIcon} from "@heroicons/react/solid";

const Container = tw.div`h-screen max-h-screen bg-palette1-100`;
const DashboardContainer = tw.div`flex-col p-2 h-full`

const Header = tw.div`flex rounded-xl bg-gray-900 h-1/12`
const TDM = tw.div`text-palette1-200 text-2xl font-bold w-2/12 self-center text-right`
const X = tw.div`text-palette1-400 text-3xl font-bold w-1/12 self-center text-center`
const BarName = tw.div`text-palette1-200 text-2xl font-bold w-3/12 self-center text-left`
//Page title section
const PageTitle = tw.div`flex flex-grow w-6/12 text-center self-center text-xl font-semibold text-palette1-400`
const FullScreenContainer = tw.div`cursor-pointer flex self-center text-palette1-200 justify-center items-center w-10 h-10 mr-3`

const DashboardBody = tw.div`flex flex-row items-stretch mt-2 h-11/12`
const BodyContainer = tw.div`flex flex-grow rounded-xl bg-palette1-300 mb-6 bg-gray-900 p-4 justify-center items-center`

const Content = tw.div`flex h-11/12 rounded-xl flex-grow m-8 border-solid border-palette1-500 flex-wrap items-stretch justify-center`

const Winner = tw.div`flex flex-col justify-around flex-grow sm:w-3/12 m-4 bg-palette1-500 rounded-xl shadow-lg p-4 m-8 border-solid border-2 border-white`
const TwoCol = tw.div`flex flex-row items-center justify-center text-white`;
const LeftColumn = tw.div`relative flex items-center justify-center text-center text-palette1-200 mr-2`;
const IconContainer = tw.div`w-16 h-16 flex justify-center items-center`
const RightColumn = tw.div`flex items-center justify-center relative text-center`;
const Label = tw.div`text-xl font-semibold`;
const UserName = tw.div`text-center text-black text-2xl font-semibold`;
const Score = tw.div`text-center text-white text-xl font-semibold`;

function WhichIcon(props) {
    if (props.index === 0) {
        return(
            <CurrencyDollarIcon/>
        )
    }
    else if (props.index === 1) {
        return(
            <LightningBoltIcon/>
        )
    }
    else if (props.index === 2) {
        return(
            <ShoppingCartIcon/>
        )
    }
    else if (props.index === 3) {
        return(
            <TrendingDownIcon/>
        )
    }
    else if (props.index === 4){
        return(
            <TrendingUpIcon/>
        )
    }
}


export default function DisplayTeams(props) {

    const handle = useFullScreenHandle();
    const [name, setName] = useState(null)


    const [winners, setWinners] = useState(null)
    //console.log(teams)

    useEffect( ()=>{
            axiosInstance.get('/parameters/get_name').then((res)=>{
                const temp = res.data;
                setName(temp.name);
            }).catch((error)=>{
                // Handle error here
            })
        },
        [setName]);

    useEffect( ()=>{
            axiosInstance.get('user/get_best_participants_detail').then((res)=>{
                const temp = res.data;
                //console.log(temp);
                setWinners(temp);
            }).catch((error)=>{
            })
            const interval = setInterval(() => {
                axiosInstance.get('user/get_best_participants_detail').then((res)=>{
                    const temp = res.data;
                    //console.log(temp);
                    setWinners(temp);
                }).catch((error)=>{
                })
            }, 30000);
            return () => clearInterval(interval);
        },
        [setWinners]);


    return(
        <FullScreen handle={handle}>
            <Container>
                <DashboardContainer>
                    <Header>
                        <TDM>
                            {displayData.tdm}
                        </TDM>
                        <X>
                            X
                        </X>
                        <BarName>
                            {name}
                        </BarName>
                        <PageTitle>
                            {displayData.pageTitle}
                        </PageTitle>
                        <FullScreenContainer onClick={handle.enter}>
                            <ArrowsExpandIcon/>
                        </FullScreenContainer>
                    </Header>
                    <DashboardBody>
                        <BodyContainer>
                            <Content>
                                {winners !== null &&
                                    winners.map((winner,index)=>{

                                        return(
                                            <Winner>
                                                <TwoCol>
                                                    <LeftColumn>
                                                        <IconContainer>
                                                            <WhichIcon index={index}/>
                                                        </IconContainer>
                                                    </LeftColumn>
                                                    <RightColumn>
                                                        <Label>
                                                            {data.label[index]}
                                                        </Label>
                                                    </RightColumn>
                                                </TwoCol>
                                                <UserName>
                                                    {winner.name}
                                                </UserName>
                                                <Score>
                                                    {data.score} {" "} {winner.score}
                                                </Score>
                                            </Winner>
                                        )
                                    })
                                }
                            </Content>
                        </BodyContainer>
                    </DashboardBody>
                </DashboardContainer>
            </Container>
        </FullScreen>
    )
}