import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import styled from "styled-components";
import {Title} from "../components/templates/Titles";
import {Subwrapper} from "../components/templates/Layouts";
import {main as data} from "../data";
import Slider from "../components/slider/slider";

import PinList from "../components/pinList/pinList";
import MyOrders from "../components/myOrders/myOrders";
import axiosInstance from "../axios";
import ScanButton from "../components/bottomButtons/scanButton";
import {checkScanner, checkStaff} from "../checkUser";
import AdminButton from "../components/bottomButtons/adminButton";
import {InformationCircleIcon} from "@heroicons/react/outline";
import Rank from "../components/rankContainer/rank";
import {useNavigate} from "react-router";

//For Badges
export const BadgeTitle = tw(Title)`mb-4 flex flex-row transition duration-1000`;
const LeftColumn = tw.div`relative flex items-center w-7/12 max-w-none text-left`;
const RightColumn = tw.div`relative flex-1 flex justify-end items-center`;
const IconContainer = styled.span`
  ${tw`transition duration-300 flex mr-6 justify-center items-center rounded-full`}
  svg {
    ${tw`w-8 h-8`}
  }
`;

export default function Main(props){

    const navigate = useNavigate();
    const [beers, setBeers] = useState(props.beers);
    const [pinList,setPinList] = useState([]);
    const [myOrders, setMyOrders] = useState([]);


    //Fetching pinList
    useEffect( ()=>{
            axiosInstance.get('/beers/pinned').then((res)=>{
                const temp = res.data;
                //console.log("pinned",res.data)
                setPinList(temp);
            }).catch((error)=>{
                // Handle error here
            })
        return () =>{
            setPinList(null);
        }
        }, [setPinList]);


    //Fetching myorders list
    useEffect( ()=>{
        axiosInstance.get('/orders/').then((res)=>{
            const temp = res.data;
            //console.log("orders",res.data)
            setMyOrders(temp);
        }).catch((error)=>{
            // Handle error here
        })
        return () =>{
            setMyOrders(null);
        }
    }, [setMyOrders]);


    //Updating drinks with websocket
    const ws = props.websocket
    //Web socket
    // Ne pas encadrer la fonction onmessage par un use effect sinon pour une raison obscure seulement certain alcools sont update en temp réel...
    ws.onmessage = function (e) {

        //console.log(typeof (e.data));
        let data = JSON.parse(e.data);
        //console.log(data);
        //console.log(data.id)
        if (data) {
            setBeers(
                [...beers].map((beer) => {
                    if (beer.id === data.id) {
                        //console.log("true")
                        return ({
                            id: data.id,
                            n: data.n,
                            name: data.name,
                            average_price:data.average_price,
                            price_hist: data.price_hist,
                            date_hist: data.date_hist,
                            is_pinned: data.is_pinned,
                            is_going_down: data.is_going_down,
                            is_crash: data.is_crash,
                        })
                    }
                    else return beer;
                })
            )
        }
    }



    //For the order section
    const [orders, setOrders] = useState(true)
    const openOrders = () => {
        if (orders === true) setOrders(false);
        else setOrders(true);
    };

    //For the pinned section
    const [pinned, setPinned] = useState(false)
    const openPinned = () => {
        if (pinned === true) setPinned(false);
        else setPinned(true);
    };

    //For badge click
    const handleBadgeInfoClick = () => {
        navigate('/grades')
    }

    return(
        <>
            <Subwrapper>

                <BadgeTitle>
                    <LeftColumn>
                        {data.titles.badges}
                    </LeftColumn>
                    <RightColumn>
                        <IconContainer>
                            <InformationCircleIcon onClick={handleBadgeInfoClick}/>
                        </IconContainer>
                    </RightColumn>
                </BadgeTitle>


                <Rank is_main={true}/>


            </Subwrapper>
            <Subwrapper>
                <Title css={[tw`mt-4`, props.beers.length === 0? tw`mb-4`: tw``]}>{data.titles.drinks}</Title>
            </Subwrapper>

                <Slider pinned={props.beers} items={beers}/>

            <Subwrapper>
                <PinList
                    ids={ Object.assign(pinList.map(beer => (beer.id))) }
                    liveItems={beers}
                    //items={pinList}
                    pinned={pinned}
                    pinFunction={openPinned}/>

                <MyOrders
                    orders={myOrders}
                    is_orders={orders}
                    orderFunction={openOrders}

                />
            </Subwrapper>

            {checkScanner() &&
                <ScanButton/>
            }
            {checkStaff() &&
                <AdminButton/>
            }
        </>


    )
}