import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import "./slider.css";
import {ChevronDownIcon, HeartIcon as FilledHeart} from "@heroicons/react/solid";
import {HeartIcon as OutlinedHeart } from "@heroicons/react/outline";
import {TrendingUpIcon} from "@heroicons/react/solid";
import {TrendingDownIcon} from "@heroicons/react/solid";

import {checkAuth} from "../../checkUser";
import {slider as data} from "../../data";
import { useNavigate } from "react-router";
import axiosInstance from "../../axios";
import {motion} from "framer-motion";
import styled from "styled-components";
const HorizontalScroll = tw.div`flex flex-nowrap overflow-x-auto px-2 max-w-screen-sm mx-auto text-palette1-400`;
const Card = tw.div`w-1/2 flex-shrink-0 rounded-xl shadow-lg mx-4 mt-4 mb-8 p-3 hover:cursor-pointer`;
const CardHeader= tw.div`flex flex-row`;
const LeftColumn = tw.div`relative flex items-center max-w-none text-left`;
const RightColumn = tw.div`relative flex-1 flex justify-end items-center`;
const Name = tw.div`font-bold text-lg pl-2 truncate`;
const LogoContainer = tw.div`h-8 w-8 flex p-2 justify-center items-center rounded-full hocus:bg-gray-500`;
const TextContainer = tw.div`font-normal text-base pl-2`;
const Price = tw.div`font-thin text-4xl text-center mt-6`;
const SoldOut = tw.div`font-normal uppercase text-4xl text-black text-center mt-6 mb-12`;
const Krash = tw.div`font-normal uppercase text-4xl text-black text-center mt-6`;
const ArrowContainer = tw.div`w-6/12 text-center mx-auto`;
// Below is arrow container if switch animation is on
//const ArrowContainer = motion(tw.div`w-6/12 text-center mx-auto`);



export default function Slider(props) {

    //console.log("props",props.items)

    const navigate = useNavigate();
    //OnClick item
    function handleClick(item) {
        const strParam = item.id.toString();
        if (item.n > 0) {
            navigate('/conso/' + strParam, {state: {item: item}})
        }
    }

    const [varPercentagePrecedent,setVarPercentagePrecedent] = useState(null);
    useEffect( ()=>{
            axiosInstance.get('/parameters/get_var_percentage_precedent').then((res)=>{
                const temp = res.data;
                setVarPercentagePrecedent(temp.var_percentage_precedent);
            }).catch((error)=>{
                // Handle error here
            })
        },
        [setVarPercentagePrecedent]);

    //OnClick Heart
    function handlePin(event,item) {
        event.cancelBubble = true;
        if(event.stopPropagation) event.stopPropagation();
        //Pin la boisson dans axios + vérifier si besoin de reload ou pas

        //console.log("click")

        if (item.is_pinned) {
            axiosInstance.put("/beers/" + item.id.toString() + "/remove", {
                id:item.id,
            }).then((res)=>{
                window.location.reload();
            })
        }
        else {
            axiosInstance.put("/beers/" + item.id.toString() + "/add", {
                id:item.id,
            }).then((res)=>{
                window.location.reload();
            })
        }
    }

    //To calculate the percentage of diminution
    function Percentage(props) {

        //console.log("percentage", props.item.price_hist);

        if (props.item.price_hist === []) return <></>
        else {
            if(props.item.price_hist.length === 1) {return <TextContainer css={tw`text-palette1-400`} >(- 0%)</TextContainer>}
            else {
                // Give shift percentage
                const variation = ((Math.abs(props.item.price_hist[props.item.price_hist.length - 2]-props.item.price_hist[props.item.price_hist.length - 1])/props.item.price_hist[props.item.price_hist.length - 2])*100).toFixed(2)
                if (props.item.is_going_down) {
                    return <TextContainer css={tw`text-palette1-400`} >(- {variation}%)</TextContainer>
                }
                else{
                    return <TextContainer css={tw`text-palette1-400`} >(+ {variation}%)</TextContainer>
                }
            }
        }
    }

    if (props.items === null) {return <></>}
    else {
        return (

            <HorizontalScroll className={"scroll-container"}>
                {props.items.map((item, index) => {

                    return (
                        <Card
                            key={index}
                            onClick={() => {handleClick(item)}}
                            css={(item.is_crash === false ? (item.n <= 0 ? tw`bg-gray-300 transition duration-500` : (item.is_going_down === true ? tw`bg-green-700 transition duration-500` : tw`bg-red-700 transition duration-500`)) : tw`bg-palette1-200 transition duration-500`)}
                        >
                            <CardHeader>
                                <LeftColumn
                                    css={item.is_crash === false ? (item.n <= 0 ? tw`text-black pt-12 w-full justify-center` : tw`text-palette1-400`) : tw`text-black`}
                                >
                                    <Name
                                        css={item.n <= 0 ? tw`pl-0` : tw``}
                                    >
                                        {item.name}
                                    </Name>
                                </LeftColumn>
                                {/*
                                {item.n > 0 &&
                                    <RightColumn>
                                            <LogoContainer onClick={(event) => {
                                                handlePin(event, props.pinned[index])
                                            }}>
                                                {props.pinned[index].is_pinned &&
                                                    <FilledHeart
                                                        css={item.is_crash === false ? (item.is_going_down === true ? tw`text-palette1-400` : tw`text-palette1-400`) : tw`text-black`}/>
                                                }
                                                {!props.pinned[index].is_pinned &&
                                                    <OutlinedHeart
                                                        css={item.is_crash === false ? (item.is_going_down === true ? tw`text-palette1-400` : tw`text-palette1-400`) : tw`text-black`}/>
                                                }
                                            </LogoContainer>
                                    </RightColumn>
                                }
                                */}
                                
                            </CardHeader>
                            {item.n > 0 ?
                                <>
                                    {item.is_crash === false && varPercentagePrecedent === true &&
                                        <Percentage item={item}/>
                                    }
                                    {item.is_crash === false && varPercentagePrecedent === false &&
                                        <TextContainer>
                                            {"("}{(((item.price_hist[item.price_hist.length - 1]-item.average_price)/item.average_price)*100).toFixed(2)}{"%)"}
                                        </TextContainer>
                                    }
                                    <Price>{item.price_hist[item.price_hist.length - 1]} {data.currency}</Price>
                                </>

                                :
                                <SoldOut>{data.soldout}</SoldOut>
                            }

                            {item.is_crash &&
                                <Krash>
                                    {data.krash}
                                </Krash>
                            }

                            {/* Cool animation but not sure its really nice
                            <ArrowContainer
                                variants={{
                                    up: { scaleY:1 },
                                    down: { scaleY:-1 },
                                }}
                                initial="up"
                                animate={item.is_going_down ? "down" : "up"}
                                transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                            >
                                <TrendingUpIcon />
                            </ArrowContainer>
                            */}

                            {item.n > 0 && item.is_crash === false &&
                                <ArrowContainer
                                    css={item.is_going_down ? tw`text-palette1-400` : tw`text-palette1-400`}>
                                    {item.is_going_down &&
                                        <TrendingDownIcon/>
                                    }
                                    {!item.is_going_down &&
                                        <TrendingUpIcon/>
                                    }
                                </ArrowContainer>
                            }

                        </Card>
                    )
                })
                }
            </HorizontalScroll>
        )
    }
}