import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {Subwrapper} from "../components/templates/Layouts";
import {useParams} from "react-router";
import {orderDetail as data} from "../data";
import axiosInstance from "../axios";
import styled from "@emotion/styled";
import {CheckIcon} from "@heroicons/react/solid";
import QRCode from "react-qr-code";
import DeleteOrderButton from "../components/orderDetail/deleteButton";

const OrderContainer = tw.div`bg-palette1-300 p-6 rounded-xl border-solid border-0`;
export const ImageContainer = styled.div`
  ${tw`rounded flex justify-center items-center mb-8`};
  
  img {
    ${tw`w-full rounded-xl shadow-lg`}
  }

  svg {
    ${tw`absolute w-96 h-96 text-palette1-100`}
  }
`;




const LabelContainer = tw.div`my-2 text-center text-black font-medium text-xl`;
const TextContentContainer = tw.div`my-2 text-center text-palette1-100 font-normal text-2xl`;


export default function OrderDetail() {

    let {id} = useParams();

    // Var to handle if command doesn't exists anymore
    const [available, setAvailable] = useState(true);

    const [order,setOrder] = useState({
        id : id,
        name: null,
        price:null,
        distributed:null,
        //qr_code:null,
    })

    useEffect(() => {
        axiosInstance.get('/orders/' + id.toString() ).then((res) => {
            const orderTemp = res.data;
            //console.log(orderTemp);
            setOrder({
                ...order,
                name: orderTemp.beer.name,
                price:orderTemp.price,
                distributed:orderTemp.distributed,
                //qr_code:orderTemp.qr_code,
            });
        }).catch((error)=>{
            if (error.response.status === 404) {
                //La commande n'existe plus
                setAvailable(false);

            }
        })
        ;
    }, []);



    return(
        <Subwrapper>
            <OrderContainer>
                {available &&
                    <>
                        {/*
                            <ImageContainer>

                                <img src={order.qr_code} alt={"QRCode"}/>
                                <CheckIcon css={order.distributed ? tw`block` : tw`hidden`}/>

                            </ImageContainer>
                        */}
                        <div css={tw`rounded flex justify-center items-center mb-8`}>
                            <div css={tw`w-full py-16 bg-white flex justify-center items-center rounded-xl shadow-lg`}>
                                <QRCode
                                    value={'{ "id":' + order.id + '}'}
                                    size={200}
                                    style={{}}
                                />
                            </div>
                            <CheckIcon css={order.distributed ? tw`block absolute w-80 h-80 text-palette1-100` : tw`hidden`}/>
                        </div>
                        <LabelContainer>{data.id}</LabelContainer>
                        <TextContentContainer>{data.number} {order.id}</TextContentContainer>
                        <LabelContainer>{data.amount}</LabelContainer>
                        <TextContentContainer>{order.price} {data.currency}</TextContentContainer>
                        <LabelContainer>{data.drink}</LabelContainer>
                        <TextContentContainer>{order.name}</TextContentContainer>
                    </>
                }
                {!available &&
                    <LabelContainer>{data.notAvailable}</LabelContainer>
                }
            </OrderContainer>
            <DeleteOrderButton id={id}/>
        </Subwrapper>
    )
}