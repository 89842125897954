import React, {useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {TrashIcon, PencilIcon, PlusIcon} from "@heroicons/react/solid";
import {dashboard as data} from "../../data";
import Popup from "./popup";
import axiosInstance from "../../axios";
import PopupValidateDelete from "./popUpValidateDelete";
import PopupNewQuantity from "./popopNewQuantity";

const GridContainer = tw.div`my-8 sm:mx-4 grid grid-cols-2 gap-6 sm:gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5`;
const Card = tw.div`cursor-default bg-palette1-300 shadow-lg rounded-xl p-3 border-palette1-300 border-solid border-2 transition duration-1000 hocus:border-t-0 hocus:border-r-0 hocus:border-b-palette1-500 hocus:border-l-palette1-500`;
const Name = tw.div`font-bold text-base pl-2 truncate mt-2`;
const Price = tw.div`font-thin text-4xl text-center my-0 sm:my-3`;
const Quantity = tw.div `font-normal text-base text-center mb-2`;
const TwoColumns= tw.div`flex flex-row`;
const LeftColumn = tw.div`relative flex justify-start items-center w-4/12 text-left`;
const MiddleColumn = tw.div`relative flex justify-center w-4/12 items-center text-center`;
const RightColumn = tw.div`relative flex justify-end w-4/12 items-center text-right`;
const LeftLogoContainer = tw.div`cursor-pointer h-8 w-8 flex p-2 text-palette1-100 justify-center items-center rounded-full hover:bg-gray-300 hover:text-palette1-500`;
const MiddleLogoContainer = tw.div`cursor-pointer h-8 w-8 flex p-2 text-palette1-100 justify-center items-center rounded-full hover:bg-gray-300 hover:text-palette1-500`;
const RightLogoContainer = tw.div`cursor-pointer h-8 w-8 flex p-2 text-palette1-100 justify-center items-center rounded-full hover:bg-gray-300 hover:text-palette1-500`;

const AddCard = tw.div`cursor-pointer bg-palette1-100 shadow-lg rounded-xl p-3 border-palette1-100 border-solid border-2 transition duration-1000 hocus:border-t-0 hocus:border-r-0 hocus:border-b-palette1-500 hocus:border-l-palette1-500`;
const AddName = tw.div`font-bold text-base pl-2 mt-2`;
const AddLogoContainer = tw.div`h-16 w-16 mt-2 flex p-2 mx-auto text-palette1-400 justify-center items-center rounded-full`;

export default function Grid(props) {
    //console.log(props.beers)
    const [pop, setPop] = useState(false);
    const [beer, setBeer] = useState(null)
    const [newQuantity, setNewQuantity] = useState(null)
    const [deleteBeer, setDeleteBeer] = useState(null)
    const [modify, setModify] = useState(false);
    const [popDelete, setPopDelete] = useState(false)
    const [popQuantity, setPopQuantity] = useState(false)

    //Functions for Cards Click
    const handleClick = (beer)=> {
        //handle opening of pop-up to modify beer
        setPop(true);
        setModify(true);
        setBeer(beer);
    }

    const handleClickAdd = (beer)=> {
        //handle opening of pop-up to add beer
        setModify(false);
        setPop(true)
    }

    const handlePopClose = () => {
        setPop(false);
        setModify(false);
        setBeer(null);
    }

    const handlePopDeleteClose = () => {
        setPopDelete(false)
        setDeleteBeer(null)
    }

    const handlePopQuantityClose = () => {
        setPopQuantity(false)
        setNewQuantity(null)
    }


    const handleDeletePop = (event,beer) => {
        event.cancelBubble = true;
        if(event.stopPropagation) event.stopPropagation();
        setPopDelete(true)
        setDeleteBeer(beer)
    }

    const handleAddQuantity = (beer) => {
        setPopQuantity(true)
        setNewQuantity(beer)
    }



    const handleDelete = (beer) => {
        //To stop propagation of onclick on card
        //event.cancelBubble = true;
        //if(event.stopPropagation) event.stopPropagation();

        //Actual function
        axiosInstance.delete('/beers/' + beer.id.toString()).then((res)=>{
            window.location.reload();
        })
    }

    return(
        <>
            <GridContainer>
                {props.beers != null && props.parameters !== null &&
                    props.beers.map((beer) => {

                        return (
                            <Card key={beer.id}>
                                <Name>
                                    {beer.name}
                                </Name>
                                <Price>
                                    {beer.average_price}{data.currency}
                                </Price>
                                <Quantity>
                                    {beer.available_quantity} {data.n}
                                </Quantity>
                                <TwoColumns>
                                    <LeftColumn>
                                        <LeftLogoContainer css={props.parameters.start === false ? tw`` : tw`bg-gray-300 text-gray-500 hover:bg-gray-300 hover:text-gray-500`} onClick={() => {props.parameters.start === false && handleClick(beer)}}>
                                            <PencilIcon/>
                                        </LeftLogoContainer>
                                    </LeftColumn>
                                    <MiddleColumn>
                                        <MiddleLogoContainer onClick={() => {handleAddQuantity(beer)}}>
                                            <PlusIcon/>
                                        </MiddleLogoContainer>
                                    </MiddleColumn>
                                    <RightColumn>
                                        <RightLogoContainer onClick={(event) => {handleDeletePop(event,beer)}}>
                                            <TrashIcon/>
                                        </RightLogoContainer>
                                    </RightColumn>
                                </TwoColumns>
                            </Card>
                        )
                    })
                }
                <AddCard onClick={handleClickAdd}>
                    <AddName>
                        Cliquez pour ajouter une boisson
                    </AddName>
                    <AddLogoContainer>
                        <PlusIcon/>
                    </AddLogoContainer>
                </AddCard>
            </GridContainer>

            {pop &&
                <Popup pop={pop} modify={modify} beer={beer} handlePopClose={handlePopClose}/>
            }

            {popDelete &&
                <PopupValidateDelete beer={deleteBeer} pop={popDelete} handleConfirm={handleDelete} handlePopClose={handlePopDeleteClose}/>
            }

            {popQuantity &&
                <PopupNewQuantity pop={popQuantity} beer={newQuantity} handlePopClose={handlePopQuantityClose}/>
            }
        </>
    )
}