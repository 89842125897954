import React, {useRef, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {popUpStafferDetail as data} from "../../data";
import {useOutsideClose} from "../templates/messagePopUp";


const Wrapper = tw.div`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none`;
const Container = tw.div`relative my-6 flex-grow mx-auto max-w-screen-sm`;
const Content = tw.div`mx-2 border-0 p-3 px-6 rounded-xl shadow-2xl relative flex flex-col bg-palette1-200 outline-none`;

const Body = tw.div`mt-8`;
const Label = tw.div`text-center text-palette1-400 font-normal text-xl bg-palette1-500 shadow-md rounded-xl my-2 w-6/12 mx-auto p-2`;
const DisplayEmail = tw.div`text-center font-bold text-2xl my-4`;
const DisplayPassword = tw.div`text-center font-bold text-2xl mt-4`;

const Footer =tw.div`flex mt-8 flex-row justify-center mb-2`;
const TextButton = tw.div`text-center w-4/12 uppercase p-2 rounded-xl bg-palette1-400 text-black font-semibold text-base cursor-pointer border-2 border-solid border-palette1-400 transition duration-500 hocus:border-palette1-500 hocus:shadow-lg`;




export default function PopUpStaffer(props) {

    const wrapperRef = useRef(null);
    useOutsideClose(wrapperRef, props.handlePopClose);

    return (
        <Wrapper>
            <Container>
                <Content ref={wrapperRef}>
                    <Body>
                        <Label>
                            {data.email}
                        </Label>
                        <DisplayEmail>
                            {props.staffer.email}
                        </DisplayEmail>
                        <Label>
                            {data.password}
                        </Label>
                        <DisplayPassword>
                            {props.staffer.scanner_mdp}
                        </DisplayPassword>
                    </Body>
                    <Footer>
                        <TextButton onClick={props.handlePopClose} css={tw`ml-3`}>
                            {data.ok}
                        </TextButton>
                    </Footer>
                </Content>
            </Container>
        </Wrapper>
    )
}