import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {Subwrapper} from "../components/templates/Layouts";
import {useParams} from "react-router";
import axiosInstance from "../axios";
import QRCode from "react-qr-code";
import {CheckIcon} from "@heroicons/react/solid";
import {badgesDetail as data} from "../data";

const ImageContainer = tw.div`w-10/12 flex items-center justify-center mx-auto`
const OrderContainer = tw.div`mt-8 bg-palette1-300 p-6 rounded-xl border-solid border-0 border-palette1-500 border-r-4 border-l-4`;
const Description = tw.div`mb-6 text-center font-bold text-2xl`;
const ScanInformation = tw.div`mb-6 text-center font-normal text-xl`;

export default function BadgeDetail(){

    let {id} = useParams();
    const [badge, setBadge] = useState({
        id:id,
        image:null,
        description:null,
        name:null,
        user_validated:null,
    });
    //console.log(badge)

    const [badgeValidation, setBadgeValidation] = useState(false)

    useEffect( ()=>{
        axiosInstance.get('parameters/get_parameters').then((res)=>{
            const temp = res.data;
            setBadgeValidation(temp.badges_validation);
        }).catch((error)=>{
            //Handle Errors
        })
    }, [setBadgeValidation])

    useEffect(() => {
        axiosInstance.get('/badges/' + id.toString() ).then((res) => {
            const badgeTemp = res.data;
            setBadge({
                ...badge,
                image:badgeTemp.image,
                description:badgeTemp.description,
                name:badgeTemp.name,
                user_validated:badgeTemp.user_validated,
            })
        }).catch((error)=>{
            //Don't do anything lol
        })
        ;
    }, [setBadge]);


    return(
        <Subwrapper>
            <ImageContainer>
                <img css={tw`max-w-full max-h-full`} src={badge.image} alt={"Badge Logo"}/>
            </ImageContainer>
            <OrderContainer>
                <Description>{badge.description}</Description>

                {badgeValidation === true &&
                    <>
                        <ScanInformation>
                            {data.validationInfo}
                        </ScanInformation>
                        <div css={tw`rounded flex justify-center items-center mb-8`}>
                            <div css={tw`w-full py-16 bg-white flex justify-center items-center rounded-xl shadow-lg`}>
                                <QRCode
                                    value={'{ "badge_id":' + badge.id + ', "user_id":' + localStorage.getItem('user_id') + '}'}
                                    size={200}
                                    style={{}}
                                />
                            </div>
                            <CheckIcon css={badge.user_validated !== null && badge.user_validated.includes(parseInt(localStorage.getItem('user_id'))) ? tw`block absolute w-80 h-80 text-palette1-100` : tw`hidden`}/>
                        </div>
                    </>
                }
            </OrderContainer>
        </Subwrapper>
    )
}