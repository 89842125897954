import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {unauthorized as data} from "../data";

const Message = tw.div`my-24 p-4 text-3xl text-center font-semibold rounded-xl border-solid border-palette1-500`;
const Container = tw.div`px-4 sm:px-6 md:px-8 xl:px-0 mt-4`;

export default function Unauthorized(props) {

    return(
        <Container>
            <Message>
                {data.message}
            </Message>
        </Container>
    )
}