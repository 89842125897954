import React, {useRef, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {popUpTeams as data} from "../../data";
import axiosInstance from "../../axios";
import {useOutsideClose} from "../templates/messagePopUp";


const Wrapper = tw.div`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none`;
const Container = tw.div`relative my-6 flex-grow mx-auto max-w-screen-sm`;
const Content = tw.div`mx-2 border-0 p-3 px-6 rounded-xl shadow-2xl relative flex flex-col bg-palette1-200 outline-none`;

const Header = tw.div`flex items-center justify-between mb-6`;
const HeaderTitle = tw.div`pl-1 text-xl sm:text-2xl font-semibold text-black text-center`;

const Body = tw.div`mb-4 flex flex-row justify-center items-center`;
const ErrorLabel = tw.p`text-center mt-0 mb-4 text-red-500 text-base font-medium`;
const TextButton = tw.div`text-center flex-1 mx-2 uppercase p-2 rounded-xl bg-palette1-400 text-black font-semibold text-base cursor-pointer border-2 border-solid border-palette1-400 transition duration-500 hocus:border-palette1-500 hocus:shadow-lg`;




export default function PopupTeamsValidation(props) {

    //Show error message if bad request
    const [error, setError] = useState(false)
    //console.log("badges", props.badges)
    const handleResetError = () => {
        setError(false);
    }

    const handleCreate = (bool) => {
        if (bool === true) {
            axiosInstance.post('/parameters/set_teams', {teams:true}).then((res)=>{
                window.location.reload();
            }).catch((error)=>{
                setError(true);
            })
        }
        else {
            axiosInstance.post('/parameters/set_teams', {teams:false}).then((res)=>{
                window.location.reload();
            }).catch((error)=>{
                setError(true);
            })
        }
    }

    const wrapperRef = useRef(null);
    useOutsideClose(wrapperRef, props.handlePopClose);

    return (
        <Wrapper>
            <Container>
                <Content ref={wrapperRef}>
                    <Header>
                        <HeaderTitle>
                            {data.title}
                        </HeaderTitle>
                    </Header>
                    <Body>
                        <TextButton css={props.teams === true ? tw`border-palette1-500` : tw``} onClick={()=>{handleCreate(true)}} css={tw`ml-3`}>
                            {data.activate}
                        </TextButton>
                        <TextButton css={props.teams === false ? tw`border-palette1-500` : tw``} onClick={()=>{handleCreate(false)}} css={tw`ml-3`}>
                            {data.desactivate}
                        </TextButton>
                    </Body>
                    <ErrorLabel css={error ? tw`block` : tw`hidden`}>
                        {data.error}
                    </ErrorLabel>
                </Content>
            </Container>
        </Wrapper>
    )
}