//Function to check if the User is part of the staff or not
function checkStaff() {
    const token = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const staff = localStorage.getItem(('is_staff'));
    if (!token || !refreshToken || !staff) {
        return false;
    }

    if (staff === 'true') return true;
    if (staff === 'false') return false;
    else {
        return false
    }

}

//Function to check if the User is part of the staff or not
function checkScanner() {
    const token = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const scanner = localStorage.getItem(('scanner'));
    if (!token || !refreshToken || !scanner) {
        return false;
    }

    if (scanner === 'true') return true;
    if (scanner === 'false') return false;
    else {
        return false
    }

}


//Function to check if user is auth or not
function checkAuth() {
    const token = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    if (!token || !refreshToken) {
        return false;
    }

    try {
        const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);
        if (tokenParts.exp < now) {
            return false;
        }

    } catch (e) {
        return false;
    }

    return true;
}

export {checkAuth, checkStaff, checkScanner};