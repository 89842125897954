import React, {useEffect, useRef, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {popUpChooseTeam as data} from "../../data";
import axiosInstance from "../../axios";
import {useOutsideClose} from "../templates/messagePopUp";
import {CheckIcon} from "@heroicons/react/solid";


const Wrapper = tw.div`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none`;
const Container = tw.div`relative my-6 flex-grow mx-auto max-w-screen-sm`;
const Content = tw.div`max-h-popTel overflow-y-scroll mx-2 border-0 p-3 px-6 rounded-xl shadow-2xl relative flex items-stretch flex-col bg-palette1-200 outline-none`;

const Header = tw.div`flex items-center justify-center mb-4`;
const HeaderTitle = tw.div`text-xl sm:text-2xl font-semibold text-black text-center`;

const SubContainer = tw.div`mb-4 p-2 flex-col justify-center items-center border-solid border-palette1-500 rounded-xl`
const SubTitle = tw.div`text-left text-base sm:text-xl font-semibold text-palette1-500`
const Body = tw.div`flex flex-col justify-center items-center`;

const NameInput = tw.input`w-10/12 px-4 my-2 bg-palette1-400 font-thin text-base text-black py-3 border-solid border-0 border-b-2 border-l-2 border-palette1-100 rounded-xl leading-tight hocus:border-palette1-500 hocus:outline-none transition duration-300 hocus:shadow-lg`;
const ButtonsContainer =tw.div`flex flex-row justify-center`;
const TextButton = tw.div`uppercase self-center text-center p-2 rounded-xl bg-palette1-400 text-black font-semibold text-base cursor-pointer border-2 border-solid border-palette1-500 shadow-lg`;
const InfoLabel= tw.p`text-center italic mt-0 mb-2 text-white text-base font-medium`;

const ListContainer = tw.div`mt-2 w-full `;
const ListItem = tw.div`flex-col bg-palette1-400 p-2 mb-2 rounded-xl`
const UnselectedDisplay = tw.div`flex flex-grow mx-1`
const ItemName = tw.div`w-9/12 text-left`
const ItemCheckBox = tw.div`w-6 h-6 bg-palette1-100 rounded-lg mr-1 text-palette1-400`;
const ItemEff = tw.div`flex-grow ml-2 text-right`

const SelectedDisplay = tw.div`flex`
const Username = tw.div`text-base rounded-xl px-1 bg-palette1-100 text-palette1-400 mr-4`

const ErrorLabel = tw.p`text-center mt-0 mb-4 text-red-500 text-base font-medium`;






export default function PopUpChooseTeam(props) {


    const [displayCreateConfirm, setDisplayCreateConfirm] = useState(false)
    const [displayJoinConfirm, setDisplayJoinConfirm] = useState(false)

    //Show error message if bad request
    const [errorCreate, setErrorCreate] = useState(false)
    const [errorJoin, setErrorJoin] = useState(false)

    const handleResetErrorCreate = () => {
        setErrorCreate(false);
    }

    const handleResetErrorJoin = () => {
        setErrorJoin(false);
    }

    const initialFormDataCreate = Object.freeze({
        name: props.name
    });
    const [formDataCreate, updateFormDataCreate] = useState(initialFormDataCreate);

    const [selectedTeam, setSelectedTeam] = useState(null)
    const [teams, setTeams] = useState(null)

    useEffect( ()=> {
        axiosInstance.get('/teams/choice').then((res)=>{
            const temp = res.data
            setTeams(temp)
        }).catch((error)=>{
            //handle error
        })
    },[setTeams])

    const [maxTeamEff,getMaxTeamEff] = useState(null)

    useEffect( ()=> {
        axiosInstance.get('/parameters/get_max_team_eff').then((res)=>{
            const temp = res.data
            getMaxTeamEff(temp.max_team_eff)
        }).catch((error)=>{
            //handle error
        })
    },[getMaxTeamEff])


    const handleChangeCreate = (e) => {
        updateFormDataCreate({
            ...formDataCreate,
            [e.target.name]: e.target.value.trim(),
        });
    };

    const handleCreate = () => {
        setDisplayCreateConfirm(prevState => !prevState)
    }

    const handleJoin = () => {
        setDisplayJoinConfirm(prevState => !prevState)
        setSelectedTeam(null)
    }

    const handleCreateConfirm = () => {
        if (formDataCreate.name === "") {setErrorCreate(true)}
        else {
            axiosInstance.post('/teams/new', formDataCreate).then((res)=>{
                window.location.reload();
            }).catch((error)=>{
                setErrorCreate(true);
            })
        }
    }

    const handleJoinConfirm = () => {
        if (selectedTeam === null) {setErrorJoin(true)}
        else {
            axiosInstance.post('/teams/set_team', {team_id:selectedTeam}).then((res)=>{
                window.location.reload();
            }).catch((error)=>{
                setErrorJoin(true);
            })
        }
    }

    const wrapperRef = useRef(null);
    useOutsideClose(wrapperRef, props.handlePopClose);

    return (
        <Wrapper>
            <Container>
                <Content ref={wrapperRef}>
                    <Header>
                        <HeaderTitle>
                            {data.title}
                        </HeaderTitle>
                    </Header>
                    <SubContainer>
                        <SubTitle>
                            {data.createTitle}
                        </SubTitle>
                        <Body>
                            <NameInput
                                type="text"
                                id="name"
                                placeholder={data.create}
                                name="name"
                                required
                                onChange={handleChangeCreate}
                                onClick={handleResetErrorCreate}
                            />
                            <ErrorLabel css={errorCreate ? tw`block` : tw`hidden`}>
                                {data.errorCreate}
                            </ErrorLabel>
                            <InfoLabel>
                                {data.createInfo}
                            </InfoLabel>
                            <ButtonsContainer>
                                <TextButton onClick={handleCreate}>
                                    {displayCreateConfirm ? data.cancel : data.validate}
                                </TextButton>
                                {displayCreateConfirm &&
                                    <TextButton css={tw`ml-4 bg-palette1-500 text-white`} onClick={handleCreateConfirm}>
                                        {data.confirm}
                                    </TextButton>
                                }
                            </ButtonsContainer>
                        </Body>
                    </SubContainer>

                    <SubContainer>
                        <SubTitle>
                            {data.joinTitle}
                        </SubTitle>
                        <InfoLabel css={tw`mb-0`}>
                            {data.joinInfo}
                        </InfoLabel>
                        <Body>
                            {teams !== null &&
                                <ListContainer>
                                    {teams.map((team, i)=>{
                                        return(
                                            <ListItem key={i} onClick={()=>{setSelectedTeam(team.id);setDisplayJoinConfirm(true)}} css={team.id !== selectedTeam ? (i%2 === 0 ? tw`` : tw`bg-palette1-300`) : (i%2 === 0 ? tw`border-solid border-2 border-palette1-500` : tw`bg-palette1-300 border-solid border-2 border-palette1-500`)}>
                                                <UnselectedDisplay>
                                                    <ItemName>
                                                        {team.name}
                                                    </ItemName>
                                                    <ItemCheckBox>
                                                        {team.id === selectedTeam ? <CheckIcon/> : <></>}
                                                    </ItemCheckBox>
                                                    <ItemEff>
                                                        {team.get_num_members + "/" + maxTeamEff}
                                                    </ItemEff>
                                                </UnselectedDisplay>
                                                {team.id === selectedTeam &&
                                                    <SelectedDisplay>
                                                        {team.members.map((member, i) => {
                                                            return (
                                                                <Username key={i}>
                                                                    {member.name}
                                                                </Username>
                                                            )
                                                        })}
                                                    </SelectedDisplay>
                                                }

                                            </ListItem>
                                            )
                                    })
                                    }
                                </ListContainer>

                            }
                            <ErrorLabel css={errorJoin ? tw`block` : tw`hidden`}>
                                {data.errorJoin}
                            </ErrorLabel>
                            {displayJoinConfirm &&
                                <ButtonsContainer>
                                    <TextButton onClick={handleJoin}>
                                        {data.cancel}
                                    </TextButton>
                                    <TextButton css={tw`ml-4 bg-palette1-500 text-white`} onClick={handleJoinConfirm}>
                                        {data.confirm}
                                    </TextButton>
                                </ButtonsContainer>
                            }
                        </Body>
                    </SubContainer>
                </Content>
            </Container>
        </Wrapper>
    )
}


