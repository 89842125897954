import React, {useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import { motion } from "framer-motion";
import styled from "styled-components";
import {useNavigate} from "react-router";
import {CheckIcon, ChevronDownIcon} from "@heroicons/react/solid";
import {EyeIcon, EyeOffIcon} from "@heroicons/react/outline";
import {myOrders as data} from "../../data";
import {Title} from "../templates/Titles";

export const PinTitle = tw(Title)`flex flex-row transition duration-1000`;
const LeftColumn = tw.div`relative flex items-center w-9/12 max-w-none text-left`;
const RightColumn = tw.div`relative flex-1 flex justify-end items-center`;
const Text = tw.div``;
const EyeContainer = tw.div`w-6 h-6 mr-4 flex text-black justify-center items-center rounded-full`;
const DrinkToggleIcon = motion(styled.span`
  ${tw`transition duration-300 flex mr-6 justify-center items-center rounded-full`}
  svg {
    ${tw`w-8 h-8`}
  }
`);

const ListSection = motion(tw.div`transition duration-200`);
const List = tw.ol`relative pl-0`;
const ListItem = tw.li`cursor-pointer list-none my-8 px-4 py-4 rounded-2xl shadow-md flex flex-row items-center bg-palette1-100`;

const FirstColumn = tw.div`relative flex-auto`;
const SecondColumn = tw.div`relative flex-auto w-8/12`;
const ThirdColumn = tw.div`relative flex-grow-0 self-center`;

const ListId = tw.div`font-thin text-xl text-center rounded-xl shadow-sm bg-palette1-400 mx-1`;
const ListText = tw.div`font-thin text-xl text-center truncate ml-2 mr-2`;
const ListCheckBox = tw.div`w-6 h-6 bg-palette1-400 rounded-lg mr-1 text-palette1-100`;

export default function MyOrders(props) {

    const [eye, setEye] = useState(false);

    //OnClick item
    const navigate = useNavigate();

    function handleClick(order) {
        //console.log("click")
        const strParam = order.id.toString();
        navigate( '/orders/' + strParam )
    }

    function handleClickEye() {
        if(eye){
            setEye(false);
        }
        else{
            setEye(true);
        }
    }

    return(
        <>
            <PinTitle>
                <LeftColumn>
                    <Text>{data.title}</Text>
                </LeftColumn>
                <RightColumn>
                    {props.is_orders &&
                        <EyeContainer onClick={handleClickEye}>
                            {eye ? <EyeIcon/> : <EyeOffIcon/>}
                        </EyeContainer>
                    }
                    <DrinkToggleIcon
                        onClick={props.orderFunction}
                        variants={{
                            collapsed: { rotate: 0 },
                            open: { rotate: -180 }
                        }}
                        initial="collapsed"
                        animate={props.is_orders ? "open" : "collapsed"}
                        transition={{ duration: 0.1, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <ChevronDownIcon />
                    </DrinkToggleIcon>
                </RightColumn>
            </PinTitle>
            <ListSection
                variants={{
                    open: { opacity: 1, height: "auto", pointerEvents:"auto"},
                    collapsed: { opacity: 0, height: 0, pointerEvents:"none"}
                }}
                initial="collapsed"
                animate={props.is_orders ? "open" : "collapsed"}
                transition={{ duration: 0.2, ease: [0.04, 0.23, 0.62, 0.98] }}
            >
                <List>
                    {props.orders.map((order, index)=> {

                        if (!eye && order.distributed === true) {
                            return (
                                <div key={index}></div>
                            )
                        }
                        else {
                            return (
                                <ListItem key={index} onClick={() => {
                                    handleClick(order)
                                }}>
                                    <FirstColumn>
                                        <ListId>
                                            {order.id}
                                        </ListId>
                                    </FirstColumn>
                                    <SecondColumn>
                                        <ListText>
                                            {order.beer.name}
                                        </ListText>
                                    </SecondColumn>
                                    <ThirdColumn>
                                        <ListCheckBox>{order.distributed ? <CheckIcon/> : <></>}</ListCheckBox>
                                    </ThirdColumn>
                                </ListItem>
                            )
                        }
                    })
                    }
                </List>
            </ListSection>
        </>
    )
}