import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {useParams} from "react-router";
import {Subwrapper} from "../components/templates/Layouts";

import axiosInstance from "../axios";
import DrinkDetailHeader from "../components/drinkDetail/drinkDetailHeader";
import Graph from "../components/drinkDetail/drinkDetailGraph";
import OrderButton from "../components/drinkDetail/orderButton";
import {drinkDetail as data} from "../data";

const BodyContainer = tw.div`my-4 p-4 rounded-xl border-solid border-0`;
const SoldOut = tw.div`font-medium uppercase text-4xl text-black text-center my-32`;

export default function DrinkDetail(props) {

    //Get websocket from props
    let ws = props.websocket
    //Get the drink id from URL
    let { id } = useParams();

    const [pinned, setPinned]=useState(false)


    const [beer, setBeer] = useState({
        name: null,
        average_price: null,
        n:0,
        price_hist: [],
        date_hist: [],
        is_pinned: null,
        is_going_down: null,
        is_crash: null,
    });

    useEffect( ()=>{
            axiosInstance.get('beers/short/' + id.toString()).then((res)=>{
                const temp = res.data;
                setBeer(temp);
                setPinned(temp.is_pinned)
            }).catch((error)=>{
                // Handle error here
            })
        },
        [id]);


    useEffect(()=> {

        ws.onmessage = function (e) {

            let data = JSON.parse(e.data);
            //console.log(typeof (data));
            //console.log(data.id, id)
            //console.log(data)

            if (data.id.toString() === id) {
                //console.log("true")
                //console.log("Received data", data.price_hist);

                setBeer(prevState => ({
                    ...prevState,
                    //name: data.name,
                    n: data.n,
                    //average_price: data.average_price,
                    price_hist: data.price_hist,
                    date_hist: data.date_hist,
                    is_pinned: data.is_pinned,
                    is_going_down: data.is_going_down,
                    is_crash: data.is_crash,
                }));
                //console.log("State Data", beer.price_hist)
            }

            return () => {
                ws = null;
            };

        }
    },[ws, id, beer]);

    return(
        <Subwrapper>
            <DrinkDetailHeader pinned={pinned} beer={beer}/>

            <BodyContainer css={beer.is_crash === false ? (beer.n > 0 ? (beer.is_going_down === true ? tw`bg-green-700 transition duration-500` : tw`bg-red-700 transition duration-500`) : tw`bg-gray-300 transition duration-500`): tw`bg-palette1-200 transition duration-500`}>
                {beer.price_hist !== [] && beer.n > 0 &&
                    <Graph
                        beer={beer}
                        data={ Object.assign(beer.price_hist.map((price, i) => ({price: beer.price_hist[i], hour: beer.date_hist[i].substring(11,19)}) )) }
                    />
                }
                {beer.price_hist !== [] && beer.n > 0 &&
                    <OrderButton beer={beer}/>
                }
                {beer.n <= 0 &&
                    <SoldOut>
                        {data.soldout}
                    </SoldOut>
                }
            </BodyContainer>

        </Subwrapper>
    )
}