import React, {useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import "./slider.css";
import {badges as data} from "../../data";
import {useNavigate} from "react-router";

const EmptyMessage = tw.div`text-palette1-500 text-center text-base font-bold mx-4 my-4`;
//const HorizontalScroll = tw.div`flex flex-nowrap overflow-x-auto px-2 max-w-screen-sm mx-auto text-palette1-400`;
const FlexContainer = tw.div`flex flex-row justify-center`
const ImageContainer = tw.div`w-20 h-20 flex-shrink-0 p-4 hover:cursor-pointer`;

export default function BadgeSlider(props) {
    const navigate = useNavigate();
    const handleClick = (item) => {
        //Opened Badge detail tab
        navigate('/badge/' + item.id.toString());
        //navigate('/badge/1')
    }

    if(props.badges === null || props.badges.length === 0) {
        return(
            <EmptyMessage>{data.emptyMessage}</EmptyMessage>
        )
    }
    else {
        return(
            <FlexContainer className={"scroll-container"}>
                {props.badges.map((item, index) => {

                    return(
                        <ImageContainer key={item.id} onClick={()=>{handleClick(item)}}>
                            <img css={tw`max-w-full max-h-full`} src={item.image} alt={"badge_logo"}/>
                        </ImageContainer>
                    )
                })}

            </FlexContainer>
        )
    }
}