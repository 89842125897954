import React, {useRef, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {XIcon} from "@heroicons/react/solid";
import {dashboardPopUp as data} from "../../data";
import axiosInstance from "../../axios";
import {useOutsideClose} from "../templates/messagePopUp";


const Wrapper = tw.div`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none`;
const Container = tw.div`relative my-6 flex-grow mx-auto max-w-screen-sm`;
const Content = tw.div`mx-2 border-0 p-3 px-6 rounded-xl shadow-2xl relative flex flex-col bg-palette1-200 outline-none`;

const Header = tw.div`flex items-center justify-between`;
const HeaderTitle = tw.div`pl-1 text-xl sm:text-2xl font-semibold text-black`;
const HeaderClose = tw.div`h-8 w-8 flex p-2 text-palette1-400 justify-center items-center rounded-full cursor-pointer`;

const Body = tw.div`mb-4 flex flex-col justify-center items-center`;

const NameInput = tw.input`w-10/12 flex-grow mt-4 mb-1 bg-palette1-400 px-4 font-thin text-base text-black py-3 border-solid border-0 border-b-2 border-l-2 border-palette1-100 rounded-xl leading-tight hocus:border-palette1-500 hocus:outline-none transition duration-300 hocus:shadow-lg`;
const PriceInput = tw.input`w-10/12 flex-grow mt-4 mb-1 bg-palette1-400 px-4 font-thin text-base text-black py-3 border-solid border-0 border-b-2 border-l-2 border-palette1-100 rounded-xl leading-tight hocus:border-palette1-500 hocus:outline-none transition duration-300 hocus:shadow-lg`;
const QuantityInput = tw.input`w-10/12 flex-grow mt-4 mb-1 bg-palette1-400 px-4 font-thin text-lg text-black py-3 border-solid border-0 border-b-2 border-l-2 border-palette1-100 rounded-xl leading-tight hocus:border-palette1-500 hocus:outline-none transition duration-300 hocus:shadow-lg`;

const InfoLabel = tw.p`text-center italic mt-0 mb-2 text-white text-base font-medium`;
const ErrorLabel = tw.p`text-center mt-0 mb-4 text-red-500 text-base font-medium`;

const Footer =tw.div`flex flex-row justify-center mb-2`;
const TextButton = tw.div`uppercase p-2 rounded-xl bg-palette1-400 text-black font-semibold text-base cursor-pointer border-2 border-solid border-palette1-400 transition duration-500 hocus:border-palette1-500 hocus:shadow-lg`;




export default function Popup(props) {

    //Show error message if bad request
    const [error, setError] = useState(false)

    const handleResetError = () => {
        setError(false);
    }

    const initialFormData = Object.freeze({
        name: `${props.modify ? props.beer.name : ''}`,
        average_price: `${props.modify ? props.beer.average_price : ''}`,
        n: `${props.modify ? props.beer.n : ''}`,
    });

    const [formData, updateFormData] = useState(initialFormData);

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim(),
        });
    };


    const handleCreate = () => {
        if(props.modify){
            axiosInstance.put('/beers/' + props.beer.id, formData).then((res)=>{
                window.location.reload();
            }).catch((error)=>{
                setError(true);
            })
        }
        else{
            axiosInstance.post('/beers/new', formData).then((res)=>{
                window.location.reload();
            }).catch((error)=>{
                setError(true);
            })
        }
    }

    const wrapperRef = useRef(null);
    useOutsideClose(wrapperRef, props.handlePopClose);

    return (
        <Wrapper>
            <Container>
                <Content ref={wrapperRef}>
                    <Header>
                        <HeaderTitle>
                            {data.title}
                        </HeaderTitle>
                        <HeaderClose onClick={props.handlePopClose}>
                            <XIcon/>
                        </HeaderClose>
                    </Header>

                    <form>
                        <Body>
                            <InfoLabel>
                                {data.info}
                            </InfoLabel>
                            <NameInput
                                type="text"
                                id="name"
                                placeholder={data.name}
                                name="name"
                                autoFocus
                                required
                                onChange={handleChange}
                                onClick={handleResetError}
                                defaultValue={props.modify ? props.beer.name : null}
                            />
                            <PriceInput
                                type="number"
                                id="average_price"
                                placeholder={data.price}
                                name="average_price"
                                required
                                onChange={handleChange}
                                onClick={handleResetError}
                                defaultValue={props.modify ? props.beer.average_price : null}
                            />
                            <QuantityInput
                                type="number"
                                id="n"
                                placeholder={data.n}
                                name="n"
                                required
                                onChange={handleChange}
                                onClick={handleResetError}
                                defaultValue={props.modify ? props.beer.n : null}
                            />
                        </Body>
                    </form>
                    <ErrorLabel css={error ? tw`block` : tw`hidden`}>
                        {data.error}
                    </ErrorLabel>
                    <Footer>
                        <TextButton onClick={props.handlePopClose} css={tw`mr-3`}>
                            {data.cancel}
                        </TextButton>
                        <TextButton onClick={handleCreate} css={tw`ml-3`}>
                            {data.validate}
                        </TextButton>
                    </Footer>
                </Content>
            </Container>
        </Wrapper>
    )
}


