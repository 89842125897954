import React, {useEffect, useState} from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";
import {drinkDetail as data} from "../../data";

import {AreaChart, Area, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid} from 'recharts';

const GraphContainer = tw.div`h-96 overflow-hidden mt-4 text-center rounded-xl shadow-lg`;

//Custom Tooltip style
const TooltipContainer = tw.div`h-20 w-20 bg-palette1-400 p-2 rounded-xl border-2 border-solid border-palette1-100`;
const TooltipPrice = tw.div`font-medium text-xl text-palette1-100 mb-2`;
const TooltipHour = tw.div`font-light text-xl text-black`;

function CustomTooltip({ active, payload, label }) {
    if (active && payload && payload.length) {
        return (
            <TooltipContainer>
                <TooltipPrice className="tooltipLabel">{payload[0].payload.price} {data.currency}</TooltipPrice>
                <TooltipHour className="tooltipDesc">{payload[0].payload.hour}</TooltipHour>
            </TooltipContainer>
        )
    }

    return null
}

export default function Graph(props) {

    return(
        <GraphContainer>
            <ResponsiveContainer css={tw`bg-palette1-400`} width="100%" height="100%">
                <AreaChart data={props.data}
                           margin={{ top: 0, right: 0, left: -10, bottom: 0 }}>
                    <CartesianGrid  />
                    <XAxis label={"hour"} tickLine={false} tick={false} height={0}/>
                    <YAxis domain={['dataMin - 1', 'dataMax + 1']}  label={"price"} tickLine={false} tick={false} width={0} />
                    <Tooltip content={<CustomTooltip />} cursor={false} />
                    <Area dot={{ stroke: '#DF973F', fill:'#DF973F', strokeWidth: 1, r: 3,strokeDasharray:''}} type="monotone" dataKey="price" stroke="#DF973F" fillOpacity={1} fill={"#F8ECCF"} />
                </AreaChart>
            </ResponsiveContainer>
            <p>Yes, this is a graph</p>

        </GraphContainer>
    )
}